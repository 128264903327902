import React from "react";
import { BASE_URL, fontSize, REFRESH_PLAQUE_INTERVAL_TIME } from "../config";
import {connect} from "react-redux"
import { getDateGematria, getDateNowFromZman, getDiffHDate, getHazkara, getVariableZman } from "../hebcalFunctions";
import AutoScroll from "../components/AutoScroll";
import Activity from "../components/Activity";

class Pessah1 extends React.Component{

    state={
        ready:false,
        needScroll:false,
        finishScroll:false
    }

    componentDidMount()
    {
        this.getData(true)
        

        if(!this.props.parent.isPreviz())
        {
            var maxtime=this.props.parent.getScreenTime(this.props.id)
            this.finishTimeout=setTimeout(()=>{
                this.finish()
            },maxtime)
        }

    }
    
    componentWillUnmount()
    {
        if(this.finishTimeout){
            clearTimeout(this.finishTimeout)
        }
    }

    finish()
    {
        if(this.state.needScroll && !this.state.finishScroll){
            setTimeout(()=>this.finish(),500)
            return false
        }

        console.log("FINISH")

        this.hide()

        setTimeout(()=>this.props.parent.next(),1500)
    }

    async getData(init=false)
    {
        console.log("GET PESSAH1")

        try{
            const req=await fetch(BASE_URL+"/getHoraireYomTov.php?yomtov=PESSAH1&org="+localStorage.getItem("org"))
            const res=await req.json()

            this.props.dispatch({type:"SET_PESSAH1",value:res})

            if(init)
            {
                setTimeout(()=>this.show(),500)
            }

            if(!this.state.ready){
                this.setState({ready:true})
            }
        }catch(e)
        {
            console.error(e)
            setTimeout(()=>this.getData(init),100)
        }
    }


    show()
    {
        var elms=document.getElementsByClassName("horaire-titre-animation")
        for(var elm of elms)
        {
            elm.style.opacity=1
            elm.style.right="0"
        }

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-bar-titre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=1
                elm.style.right="0"
            }
        },200)

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-cadre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=1
                elm.style.right="0"
            }
        },400)

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-text-animation")
            for(var elm of elms)
            {
                elm.style.opacity=1
            }
        },1000)
       
    }

    hide()
    {
        var elms=document.getElementsByClassName("horaire-text-animation")
        for(var elm of elms)
        {
            elm.style.opacity=0
        }
 
        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-cadre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=0
                elm.style.right="50px"
            }
        },200)

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-bar-titre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=0
                elm.style.right="100px"
            }
        },400)

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-titre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=0
                elm.style.right="50px"
            }
        },600)
    }

    getNextDay(number,date)
    {
        while(date.getDay()!=number)
        {
            date.setDate(date.getDate()+1)
        }

        return date
    }

    getHoraire(type)
    {
        const horaire=this.props.horaire

        var data=[]

        for(var h of horaire)
        {
            if(h.type!=type){
                continue
            }

            data.push({
                nom:h.nom,
                time:h.horaire_fixe
            })
            
        }

        data.sort(function(a,b){
            const ah=parseInt(a.time.split(":")[0])
            const am=parseInt(a.time.split(":")[1])
            const bh=parseInt(b.time.split(":")[0])
            const bm=parseInt(b.time.split(":")[1])

            var datea=new Date()
            datea.setHours(ah,am)

            var dateb=new Date()
            dateb.setHours(bh,bm)

            if(datea==dateb){
                return 0
            }
            return datea<dateb?-1:1
        })

        return data
    }

   
    render()
    {
        if(this.props.horaire.length==0 && !this.state.ready)
        {
            return(
                <Activity/>
            )
        }

        if(this.props.horaire.length==0 && !this.props.parent.isPreviz())
        {
            this.props.parent.next()
            return null
            return(
                <p>aucunes données dispo</p>
            )
        }

        const horaireVeille=this.getHoraire("VEILLE")
        const horaireJour1=this.getHoraire("JOUR1")
        const horaireJour2=this.getHoraire("JOUR2")

        return(
            <div style={{
                display:"flex",
                alignItems:"center",
                flexDirection:"column",
                width:"100vw",
            }}>

                <div style={{width:"100vw",display:"flex",alignItems:"center",flexDirection:"column"}}>
                    <span className="horaire-titre-animation" style={{fontFamily:"'Alice', serif",fontSize:fontSize(25)}}>1ere fetes de Pessah</span>
                    <div className="horaire-bar-titre-animation" style={{height:"0.5vh",width:"40vw",borderRadius:"25px",backgroundColor:"#000"}}></div>
                </div>

                <div style={{
                    display:"flex",
                    justifyContent:"space-around",
                    flexDirection:"row",
                    width:"100vw",
                    marginTop:"1vh"
                }}>


                    <div className="horaire-cadre-animation" style={{
                        backgroundImage:"url(/images/cadre-1.png)",
                        backgroundSize:"contain",
                        backgroundRepeat:"no-repeat",
                        display:"flex",
                        height:"70vh",
                        width:"69vh",
                        justifyContent:"center",
                        alignItems:"center",
                        border:"0px solid red",
                        
                    }}>

                        <div style={{
                            display:"flex",
                            flexDirection:"column",
                            height:"60vh",
                            width:"59vh",
                            alignItems:"center",
                            border:"0px solid green",
                        }}>

                        <span className="horaire-titre-animation" style={{fontFamily:"'Lora', serif",fontSize:fontSize(25),marginBottom:"3vh"}}>Veille & 1er Jour</span>

                            <AutoScroll 
                            onNeedScroll={()=>this.setState({needScroll:true})} 
                            onFinishScroll={()=>this.setState({finishScroll:true})} 
                            slow={30} delay={5000} style={{width:"59vh",display:"flex",alignItems:"center",flexDirection:"column"}}>
                                   
                                    {
                                        horaireVeille.map((item,index)=>(
                                            <div key={index} className="horaire-text-animation" style={{
                                                display:"flex",
                                                justifyContent:"space-between",
                                                width:"100%",
                                                paddingLeft:"10%",
                                                paddingRight:"10%",
                                                marginBottom:"2vh"
                                            }}>
                                                <span style={{fontFamily:"Bodoni FLF",fontSize:fontSize(13)}} >{item.nom}</span>
                                                <span style={{fontFamily:"Bodoni FLF",fontSize:fontSize(13)}} >{item.time}</span>
                                            </div>
                                        ))
                                    }

                                    {
                                        horaireJour1.map((item,index)=>(
                                            <div key={index} className="horaire-text-animation" style={{
                                                display:"flex",
                                                justifyContent:"space-between",
                                                width:"100%",
                                                paddingLeft:"10%",
                                                paddingRight:"10%",
                                                marginBottom:"2vh",
                                                paddingTop:index==0?"3vh":0,
                                                marginTop:index==0?"1vh":0,
                                                borderTop:index==0?"0.5vh solid #000":0,
                                            }}>
                                                <span style={{fontFamily:"Bodoni FLF",fontSize:fontSize(13)}} >{item.nom}</span>
                                                <span style={{fontFamily:"Bodoni FLF",fontSize:fontSize(13)}} >{item.time}</span>
                                            </div>
                                        ))
                                    }

                                    
                            </AutoScroll> 
                            
                        </div>
                        
                    </div>

                    <div className="horaire-cadre-animation" style={{
                        backgroundImage:"url(/images/cadre-1.png)",
                        backgroundSize:"contain",
                        backgroundRepeat:"no-repeat",
                        display:"flex",
                        height:"70vh",
                        width:"69vh",
                        justifyContent:"center",
                        alignItems:"center",
                        border:"0px solid red",
                        
                    }}>

                        <div style={{
                            display:"flex",
                            flexDirection:"column",
                            height:"60vh",
                            width:"59vh",
                            alignItems:"center",
                            border:"0px solid green",
                        }}>

                            <span className="horaire-titre-animation" style={{fontFamily:"'Lora', serif",fontSize:fontSize(25),marginBottom:"3vh"}}>2eme Jour</span>


                            <AutoScroll 
                            onNeedScroll={()=>this.setState({needScroll:true})} 
                            onFinishScroll={()=>this.setState({finishScroll:true})}
                            style={{width:"32vw",display:"flex",alignItems:"center",flexDirection:"column"}}>
                                    {
                                        horaireJour2.map((item,index)=>(
                                            <div key={index} className="horaire-text-animation" style={{
                                                display:"flex",
                                                justifyContent:"space-between",
                                                width:"100%",
                                                paddingLeft:"10%",
                                                paddingRight:"10%",
                                                marginBottom:"2vh"
                                            }}>
                                                <span style={{fontFamily:"Bodoni FLF",fontSize:fontSize(13)}} >{item.nom}</span>
                                                <span style={{fontFamily:"Bodoni FLF",fontSize:fontSize(13)}} >{item.time}</span>
                                            </div>
                                        ))
                                    }
                                    
                            </AutoScroll> 
                             
                        </div>
                        
                    </div>
                
                </div>
        
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
      horaire:state.pessah1,
      config:state.config
    };
  };

export default connect(mapStateToProps)(Pessah1)