import React from "react";
import { Animated } from "react-animated-css";
import { fontSize } from "../config";
import { getHebrewDate, getNextEntreeShabbat, getNextParasha, getNextSortieShabbat, HEBCAL } from "../hebcalFunctions";



export default class Header extends React.Component{

    state={
        time:"",
        parasha:"",
        entree:null,
        sortie:null,
        hebrewdate:null,
        twoPoint:true,
    }

    portrait=(localStorage.getItem("portrait") === "true")

    setTime()
    {
        const today=new Date()
        const newtime=("0" + today.getHours()).slice(-2)+":"+("0" + today.getMinutes()).slice(-2)+":"+("0" + today.getSeconds()).slice(-2)

        this.setState({time:newtime})
    }
   
    componentDidMount()
    {
        this.intervalTime=setInterval(()=>this.setTime(),10)

        this.setData()

        this.intervalData=setInterval(()=>this.setData(),1000)
        
    }

    componentWillUnmount()
    {
        clearInterval(this.intervalTime)
        clearInterval(this.intervalData)
    }

   
    setData()
    {
        
        this.setState({
            parasha:getNextParasha(new Date()),
            entree:getNextEntreeShabbat(new Date()),
            sortie:getNextSortieShabbat(new Date()),
            hebrewdate:getHebrewDate(),
        })
    }

    formatTime(date)
    {

        if(!date){
            return ""
        }

        return ("0" + date.getHours()).slice(-2)+":"+("0" + date.getMinutes()).slice(-2)
    }
    

    render()
    {

        if(this.portrait)
        {
            return(
                <div style={{width:"100vw",display:"flex",justifyContent:"center",alignItems:"center",marginTop:"2vh",flexDirection:"column"}}>

                    <div style={{width:"95vw",display:"flex",justifyContent:"space-around",alignItems:"center",flexDirection:"row"}}>

                        <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",width:"35vw"}}>
                            <span style={{fontFamily:"'Alice', serif",fontSize:fontSize(12),fontWeight:"600"}}>{this.state.parasha}</span>
                            <span style={{fontFamily:"'Alice', serif",fontSize:fontSize(12),marginLeft:"1vw",marginTop:"0vh"}}>
                                <img src="/images/allumage.png" style={{height:"3vh",marginRight:"0.2vw",marginTop:"-1vh"}}/>
                                {this.formatTime(this.state.entree)}
                                <img src="/images/havdalah.png"  style={{height:"3vh",marginRight:"0.2vw",marginTop:"-0.5vh",marginLeft:"2vw"}}/>
                                {this.formatTime(this.state.sortie)}
                            </span>
                        </div>

                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between",width:"20vw"}}>
                            <span style={{fontFamily:"'Lora', serif",fontSize:fontSize(20),fontWeight:"500",textAlign:"center"}}>{this.state.time}</span>
                        </div>

                        <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",width:"35vw"}}>
                            <span style={{fontFamily:"'Alice', serif",fontSize:fontSize(13),fontWeight:"500",textAlign:"center"}}>{this.state.hebrewdate?.split(" ").slice(0,2).join(" ")}</span>
                            <span style={{fontFamily:"'Alice', serif",fontSize:fontSize(13),fontWeight:"500",textAlign:"center"}}>{this.state.hebrewdate?.split(" ").slice(2).join(" ")}</span>
                        </div>

                    </div>
                    <div className="border" style={{height:"0.3vh",marginTop:"1vh",width:"90vw",borderRadius:"20px",backgroundColor:"#000"}}></div>
                </div>

            )
        }

        
        return(
            <div style={{width:"100vw",display:"flex",justifyContent:"center",alignItems:"center",marginTop:"2vh",flexDirection:"column"}}>
                <div style={{width:"90vw",display:"flex",justifyContent:"space-around",alignItems:"center",flexDirection:"row"}}>
                        
                        <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",width:"35vw"}}>
                            <span style={{fontFamily:"'Alice', serif",fontSize:fontSize(12),fontWeight:"600"}}>{this.state.parasha}</span>
                            <span style={{fontFamily:"'Alice', serif",fontSize:fontSize(12),marginLeft:"1vw",marginTop:"0vh"}}>
                                <img src="/images/allumage.png" style={{height:"3vh",marginRight:"0.2vw",marginTop:"-1vh"}}/>
                                {this.formatTime(this.state.entree)}
                                <img src="/images/havdalah.png"  style={{height:"3vh",marginRight:"0.2vw",marginTop:"-0.5vh",marginLeft:"1vw"}}/>
                                {this.formatTime(this.state.sortie)}
                            </span>
                        </div>

                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between",width:"20vw"}}>       
                            <div className="border" style={{height:"5vh",width:"0.2vw",borderRadius:"20px",backgroundColor:"#000"}}></div>
                            <span style={{fontFamily:"'Lora', serif",fontSize:fontSize(25),fontWeight:"500"}}>{this.state.time}</span>
                            <div className="border" style={{height:"4.5vh",width:"0.2vw",borderRadius:"20px",backgroundColor:"#000"}}></div>
                        </div>

                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",width:"35vw"}}>       
                            <span style={{fontFamily:"'Alice', serif",fontSize:fontSize(15),fontWeight:"500"}}>{this.state.hebrewdate}</span>
                        </div>
                        
                    </div>
                    <div className="border" style={{height:"0.5vh",width:"90vw",borderRadius:"20px",backgroundColor:"#000"}}></div>
            </div>
            
        )
    }
}


