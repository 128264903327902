import React from "react";
import { fontSize, generateGuid } from "../config";


export default class AutoScroll extends React.Component{

    constructor(props)
    {
        super(props)
        this.id=generateGuid()
        this.delay=3000
        this.slow=100

        if(this.props.delay){
            this.delay=this.props.delay
        }
        if(this.props.slow){
            this.slow=this.props.slow
        }
        
    }

    timout=[]

    componentWillUnmount()
    {
        for(var t of this.timout)
        {
            if(t){
                clearTimeout(t)
            }
        }
    }

    componentDidMount()
    {
        this.timout.push(setTimeout(()=>{

            if(this.props.isScrollable)
            {
                this.props.isScrollable(this.needScrolll())
            }

            if(this.needScrolll())
            {
                this.reloadVertical(true)

                if(this.props.onNeedScroll){
                    this.props.onNeedScroll()
                }
            }

        },1000))
        
    }

    needScrolll()
    {
        var elm=document.getElementById("autoscroll-"+this.id)
        return elm.scrollHeight > elm.clientHeight
    }

    pageScrollVertical(i) 
    {
        var elm=document.getElementById("autoscroll-"+this.id)

        
        const max=elm.scrollHeight-elm.clientHeight
        //console.log(max,i)


        elm.scrollTop=i+1

        if(i<max)
        {
            this.timout.push(setTimeout(()=>this.pageScrollVertical(i+1),this.slow))

        }else
        {
            if(this.props.onFinish)
            {
                this.props.onFinish()
            }

            this.reloadVertical(false)
        }
    }

    pageScrollTopVertical(i) 
    {
        const min=0

        var elm=document.getElementById("autoscroll-"+this.id)


        if(i==-1){
            i=elm.scrollHeight-elm.clientHeight
        }
        //console.log(min,i)

        elm.scrollTop=i-1
        
        
        if(i>min)
        {
            this.timout.push(setTimeout(()=>this.pageScrollTopVertical(i-1),10))

        }else
        {
            this.reloadVertical(true)
        }
    }

    reloadVertical(init)
    {
        var elm=document.getElementById("autoscroll-"+this.id)

        if(!elm)
        {
            console.log("AUTO SCROLL WAIT LOADING")
            this.timout.push(setTimeout(()=>this.reloadVertical(init),500))
            return false
        }

        if(!init)
        {
            this.timout.push(setTimeout(()=>{
                if(this.props.onFinishScroll){
                    this.props.onFinishScroll()
                }

                setTimeout(()=>this.pageScrollTopVertical(-1),500)
            },this.delay))
            
        }else
        {
            this.timout.push(setTimeout(()=>this.pageScrollVertical(0),this.delay))
        }
    }


    render()
    { 
        var style={}
        if(this.props.style){
            var style= JSON.parse(JSON.stringify(this.props.style))
        }
       
        style.overflowY="scroll"
        style.overflowX="scroll"

        var otherProps={}

        if(this.props.html){
            otherProps.dangerouslySetInnerHTML={__html:this.props.html}
        }

        return(
            <div id={"autoscroll-"+this.id} style={style} {...otherProps} >
                {this.props.children}
            </div>
            
        )
    }
}
