import React from "react";
import { Button } from "react-bootstrap";



export default class Error extends React.Component{

    render()
    {
        return(
            <div style={{
                display:"flex",
                flex:1,
                width:"100vw",
                height:"100vh",
                justifyContent:"center",
                alignItems:"center",
                
            }}>

                <div style={{
                    backgroundColor:"white",
                    borderRadius:"20px",
                    width:"40%",
                    height:"35%",
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center",
                    justifyContent:"center",
                    paddingBottom:"30px"
                }}>

                    <img src="/images/warning.png" style={{width:"20%"}} />

                    <h1 style={{color:"#d9534f",marginBottom:'30px'}}>Erreur</h1>

                    <h4>{this.props.error}</h4>

                </div>
            
            </div>
        )
    }
}