import React from "react";
import {Animated} from "react-animated-css";
import { fontSize } from "../config";

export default class BigPlaque extends React.Component{

    getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }

    componentDidMount()
    {
        var random=this.getRandomArbitrary(0,50)
        //document.getElementById("kindle-"+this.props.item.id+"-1").currentTime=random
        //document.getElementById("kindle-"+this.props.item.id+"-2").currentTime=random
    }

    render()
    {
        const item=this.props.item

        if(!item){
            return null
        }

        var textDate=item.jourHE+" "+item.moisHE+" "+item.anneeHE

        if(item.datetimeFR)
        {
            textDate+=" - "+new Date(item.datetimeFR).toLocaleDateString()
        }

        const candle="images/candles/candle-"+Math.round(this.getRandomArbitrary(1,8))+".gif"

        return(
            <Animated 
            isVisible={this.props.visible} 
            animationIn="fadeIn" 
            animationOut="fadeOut"
            animationInDuration={1000}
            animationOutDuration={1000}
            >

                <div  style={{
                    backgroundImage:"url(/images/plaque-bg2.png)",
                    backgroundSize:"contain",
                    backgroundRepeat:"no-repeat",
                    marginTop:"1.5vh",
                    height:"40.9vh",
                    width:"54vw",
                    position:"relative",
                    display:"flex",
                    flexDirection:"row",
                    justifyContent:"space-between",
                    border:"0px solid red",
                    overflowX:"hidden"
                }}>

                <img
                    style={{
                        height:"15vh",
                        marginTop:"11vh",
                    }}
                    src={candle} />
                
                    <div style={{
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        justifyContent:"flex-start",
                        marginTop:"2vmin",
                    }}>
                        <span style={{fontFamily:"SefaradiYehezkel",color:"#CCAC3C",fontSize:fontSize(12),letterSpacing:fontSize(0.6),marginBottom:"1vh"}}>לעילוי נשמת</span>
                        <span style={{maxLines:1,fontFamily:"SefaradiYehezkel",color:"#CCAC3C",fontSize:fontSize(18),letterSpacing:fontSize(0.6)}}>{item.nomHE} ז"ל</span>
                        <span style={{maxLines:1,color:"#CCAC3C",fontFamily:"'Alice', serif",fontSize:fontSize(13)}}>{item.nomFR}</span>
                        <span style={{color:"#CCAC3C",fontFamily:"'Alice', serif",fontSize:fontSize(15)}}>שנלב"ע {item.dateHE}</span>
                        <span style={{color:"#CCAC3C",fontFamily:"'Alice', serif",fontSize:fontSize(13)}}>{textDate}</span>
                        <span style={{color:"#CCAC3C",fontFamily:"'Alice', serif",fontSize:fontSize(15)}}>ת.נ.צ.ב.ה</span>
                    </div>

                
                    <img
                    style={{
                        height:"15vh",
                        marginTop:"11vh",
                    }}
                    src={candle} />

               

                </div>
            </Animated>
        )
    }
}