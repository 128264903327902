import React from "react";
import { BASE_URL, fontSize, REFRESH_PLAQUE_INTERVAL_TIME } from "../config";
import {connect} from "react-redux"
import { getDateGematria, getDateNowFromZman, getDiffHDate, getHazkara, getVariableZman } from "../hebcalFunctions";
import AutoScroll from "../components/AutoScroll";
import hayomYom from "../data/hayomYom";
import { HDate } from "@hebcal/core";
import Activity from "../components/Activity";
import { Carousel } from 'react-responsive-carousel';

export default class Chiourim extends React.Component{

    state={
        needScroll:true,
        finishScroll:false,
        data:[],
        ready:false,
    }

    componentDidMount()
    {
        //setTimeout(()=>this.show(),500)
        this.getData(true)
        
        if(!this.props.parent.isPreviz())
        {
            var maxtime=this.props.parent.getScreenTime(this.props.id)
            this.finishTimeout=setTimeout(()=>{
                this.finish()
            },maxtime)
        }
    }


    async getData(init=false)
    {
        console.log("GET CHIOURIM")

        try{
            const req=await fetch(BASE_URL+"/getChiourimScreen.php?org="+localStorage.getItem("org"))
            const res=await req.json()

            var finalRes=[]

            for(var c of res)
            {
                var jours=[]
                if(c.dimanche=="1"){
                    jours.push("Dimanche")
                }
                if(c.lundi=="1"){
                    jours.push("Lundi")
                }
                if(c.mardi=="1"){
                    jours.push("Mardi")
                }
                if(c.mercredi=="1"){
                    jours.push("Mercredi")
                }
                if(c.jeudi=="1"){
                    jours.push("Jeudi")
                }
                if(c.vendredi=="1"){
                    jours.push("Vendredi")
                }
                if(c.samedi=="1"){
                    jours.push("Chabbat")
                }

                for(var j of jours)
                {
                    if(finalRes.find(i=>i.type==j)){

                        var index=finalRes.findIndex(i=>i.type==j)
                        finalRes[index].items.push(c)
                    }else{
                        finalRes.push({
                            type:j,
                            items:[c]
                        })
                    }
                }
            }

            finalRes.sort((a,b)=>{
                var js=["Dimanche","Lundi","Mardi","Mercredi","Jeudi","Vendredi","Chabbat"]
                var indexa=js.findIndex(i=>i==a.type)
                var indexb=js.findIndex(i=>i==b.type)
                if(indexa==indexb){
                    return 0
                }
                return indexa>indexb?1:-1
            })

            var sortedChiourim=finalRes.map(j=>{
                j.items=j.items.sort(function(a,b){
                    const ah=parseInt(a.horaire.split(":")[0])
                    const am=parseInt(a.horaire.split(":")[1])
                    const bh=parseInt(b.horaire.split(":")[0])
                    const bm=parseInt(b.horaire.split(":")[1])
        
                    var datea=new Date()
                    datea.setHours(ah,am)
        
                    var dateb=new Date()
                    dateb.setHours(bh,bm)
        
                    return (datea - dateb);
                })

                return j
            })

            var splited=[]

            const chunkSize = 2;
            for (let i = 0; i < sortedChiourim.length; i += chunkSize) {
                const chunk = sortedChiourim.slice(i, i + chunkSize);
                splited.push(chunk)
            }

            console.log(splited)

            this.setState({data:splited})

            if(init)
            {
                setTimeout(()=>this.show(),500)
            }

            if(!this.state.ready){
                this.setState({ready:true})
            }
        }catch(e)
        {
            console.error(e)
            setTimeout(()=>this.getData(init),100)
        }
    }

    componentWillUnmount()
    {
        if(this.finishTimeout){
            clearTimeout(this.finishTimeout)
        }
    }

    finish()
    {
        if(this.state.needScroll && !this.state.finishScroll){
            setTimeout(()=>this.finish(),500)
            return false
        }

        console.log("FINISH")

        this.hide()

        setTimeout(()=>this.props.parent.next(),1500)
    }


    show()
    {
        var elms=document.getElementsByClassName("horaire-titre-animation")
        for(var elm of elms)
        {
            elm.style.opacity=1
            elm.style.right="0"
        }

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-bar-titre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=1
                elm.style.right="0"
            }
        },200)


        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-text-animation")
            for(var elm of elms)
            {
                elm.style.opacity=1
            }
        },1000)
       
    }

    hide()
    {
        var elms=document.getElementsByClassName("horaire-text-animation")
        for(var elm of elms)
        {
            elm.style.opacity=0
        }

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-bar-titre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=0
                elm.style.right="100px"
            }
        },400)

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-titre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=0
                elm.style.right="50px"
            }
        },600)
    }

   

    renderIndicator=(clickHandler,isSelected,index,label)=>
    {
        return null

        return(
            <div 
            style={{
                display:"inline-block",
                borderRadius:"100%",
                height:"2vh",
                width:"2vh",
                marginLeft:"2vh",
                marginRight:"2vh",
                backgroundColor:isSelected?"black":"grey"
            }}></div>
        )
    }
   
    render()
    {
        if(this.state.data.length==0 && !this.state.ready)
        {
            return(
                <Activity/>
            )
        }

        return(
            <div style={{
                display:"flex",
                alignItems:"center",
                flexDirection:"column",
                width:"100vw",
            }}>

                <div style={{width:"100vw",display:"flex",alignItems:"center",flexDirection:"column"}}>
                    <span className="horaire-titre-animation" style={{fontFamily:"'Alice', serif",fontSize:fontSize(25)}}>Horaires des Cours</span>
                    <div className="horaire-bar-titre-animation" style={{height:"0.5vh",width:"35vw",borderRadius:"25px",backgroundColor:"#000",marginBottom:"2vh"}}></div>
                </div>

                <div className="horaire-text-animation" style={{width:"100vw"}}>    
                    <Carousel
                    showThumbs={false}
                    showArrows={false}
                    swipeable={false}
                    showStatus={false}
                    autoPlay
                    infiniteLoop
                    interval={30000}
                    renderIndicator={this.renderIndicator}
                    onChange={(index)=>{
                        if(index==0){
                            this.setState({finishScroll:true})
                        }
                    }}
                    style={{}}
                    >
                        {
                            this.state.data.map((arr,index)=>(
                                <div key={index} style={{width:"100vw",display:"flex",justifyContent:"space-around",border:"0px solid black"}}>

                                    {
                                        arr.map((item,index2)=>{
                                            return(
                                                <div key={index2} style={{height:"65vh",width:"48vw",display:"flex",flexDirection:"column",justifyContent:"flex-start",border:"0px solid red",padding:"0 2vh"}}>
                                        
                                                    <div style={{display:"flex",justifyContent:"flex-start"}}>
                                                        <span style={{fontFamily:"'Alice', serif",fontSize:fontSize(20)}}>{item.type}:</span>
                                                    </div>
            
                                                    {
                                                        item.items.map((c,index3)=>{
                                                            return(
                                                                <div key={index3} style={{display:"flex",justifyContent:"flex-start",flexWrap:"wrap",alignItems:"center"}}>
                                                                    <span style={{fontFamily:"'Alice', serif",fontSize:fontSize(13),marginRight:15}}>- {c.horaire}:</span>
                                                                    <span style={{fontFamily:"'Alice', serif",fontSize:fontSize(13),marginRight:15,fontWeight:"600"}}>{c.sujet}</span>
                                                                    <span style={{fontSize:fontSize(10)}}>{c.intervenant}</span>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
        
            </div>
        )
    }
}