import React from "react";
import {Animated} from "react-animated-css";
import { fontSize } from "../config";

export default class MiniPlaque extends React.Component{

    getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }

    componentDidMount()
    {
        var random=this.getRandomArbitrary(0,50)
        //document.getElementById("kindle-"+this.props.item.id+"-1").currentTime=random
        //document.getElementById("kindle-"+this.props.item.id+"-2").currentTime=random
    }

    render()
    {
        const item=this.props.item

        var textDate=item.jourHE+" "+item.moisHE+" "+item.anneeHE

        if(item.datetimeFR)
        {
            textDate+=" - "+new Date(item.datetimeFR).toLocaleDateString()
        }

        const candle="images/candles/candle-"+Math.round(this.getRandomArbitrary(1,8))+".gif"

        return(
            <Animated 
            isVisible={this.props.visible} 
            animationIn="fadeIn" 
            animationOut="fadeOut"
            animationInDuration={1000}
            animationOutDuration={1000}
            >

                <div  style={{
                    backgroundImage:"url(/images/plaque-bg2.png)",
                    backgroundSize:"contain",
                    backgroundRepeat:"no-repeat",
                    height:"14.9vmin",
                    width:"18vw",
                    position:"relative",
                    display:"flex",
                    flexDirection:"row",
                    justifyContent:"space-between",
                    border:"0px solid red",
                    overflowX:"hidden"
                }}>

                <img
                    style={{
                        height:"6vh",
                        marginTop:"4vh",
                    }}
                    src={candle} />

                
                
                    <div style={{
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        justifyContent:"flex-start",
                        marginTop:"1vmin",
                    }}>
                        <span style={{fontFamily:"SefaradiYehezkel",color:"#CCAC3C",fontSize:fontSize(4),letterSpacing:fontSize(0.6),marginBottom:"0.3vh"}}>לעילוי נשמת</span>
                        <span style={{maxLines:1,fontFamily:"SefaradiYehezkel",color:"#CCAC3C",fontSize:fontSize(5),letterSpacing:fontSize(0.6)}}>{item.nomHE} ז"ל</span>
                        <span style={{maxLines:1,color:"#CCAC3C",fontFamily:"'Alice', serif",fontSize:fontSize(4)}}>{item.nomFR}</span>
                        <span style={{color:"#CCAC3C",fontFamily:"'Alice', serif",fontSize:fontSize(5)}}>שנלב"ע {item.dateHE}</span>
                        <span style={{color:"#CCAC3C",fontFamily:"'Alice', serif",fontSize:fontSize(4)}}>{textDate}</span>
                        <span style={{color:"#CCAC3C",fontFamily:"'Alice', serif",fontSize:fontSize(5)}}>ת.נ.צ.ב.ה</span>
                    </div>

                
                    <img
                    style={{
                        height:"6vh",
                        marginTop:"4vh",
                    }}
                    src={candle} />

               

                </div>
            </Animated>
        )
    }
}


/*


                <div style={{
                    marginRight:"1vmin",
                    marginTop:"1vmin",
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"flex-end",
                    justifyContent:"center"
                }}>
                    <span style={{fontFamily:"SefaradiYehezkel",color:"#CCAC3C",fontSize:"calc(0.5vh + 0.5vw)",letterSpacing:"1px"}}>לעילוי נשמת</span>
                    <span style={{fontFamily:"SefaradiYehezkel",color:"#CCAC3C",fontSize:"calc(0.2vh + 0.7vw)",letterSpacing:"1px",}}>{item.nomHE} ז"ל</span>
                    <span style={{color:"#CCAC3C",fontSize:"calc(0.2vh + 0.5vw)",letterSpacing:"1px"}}>{item.nomFR}</span>
                    <span style={{color:"#CCAC3C",fontSize:"calc(0.2vh + 0.6vw)",letterSpacing:"1px"}}>שנלב"ע {item.dateHE}</span>
                    <span style={{color:"#CCAC3C",fontSize:"calc(0.2vh + 0.5vw)",letterSpacing:"1px"}}>{item.jourHE} {item.moisHE} {item.anneeHE} - {date.toLocaleDateString()}</span>
                    <span style={{color:"#CCAC3C",fontSize:"1.5vmin",letterSpacing:"1px"}}>ת.נ.צ.ב.ה</span>
                </div>
*/


/*

<span style={{color:"#CCAC3C",fontSize:"calc(1vh + 1vw)",letterSpacing:"1px"}}>{item.nomFR}</span>
                    <span style={{color:"#CCAC3C",fontSize:"1.5vmin",letterSpacing:"1px"}}>שנלב"ע {item.dateHE}</span>
                    <span style={{color:"#CCAC3C",fontSize:"1.3vmin",letterSpacing:"1px"}}>{item.jourHE} {item.moisHE} {item.anneeHE} - {date.toLocaleDateString()}</span>
                    <span style={{color:"#CCAC3C",fontSize:"1.5vmin",letterSpacing:"1px"}}>ת.נ.צ.ב.ה</span>
*/

/*
<video
                    id={"kindle-"+item.id+"-1"} 
                    style={{
                        height:"6vh",
                        marginTop:"4vh",
                        
                    }}
                    preload="auto"
                    onPlay={()=>this.props.onCandlePlay("kindle-"+item.id+"-1")} 
                    src={"data:video/mp4;base64,"+candle} autoPlay muted loop></video>
*/