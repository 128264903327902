import React from "react";
import CustomDesign1 from "../components/CustomDesign1";
import { BASE_URL, fontSize, REFRESH_PLAQUE_INTERVAL_TIME } from "../config";


export default class CustomText extends React.Component{

    state={
        previzReady:false,
        previzScreen:null,
        needScroll:false,
        finishScroll:false,
    }

    screenId=null

    init()
    {
        this.setState({
            previzReady:false,
            previzScreen:null
        })

        if(!this.props.parent.isPreviz())
        {
            const screen=this.props.parent.getCurrentScreen()
            if(!screen){
                return false
            }
            this.screenId=screen.id
            var maxtime=this.props.parent.getScreenTime(screen.id)
           this.finishTimeout=setTimeout(()=>{
                this.finish()
            },maxtime)
        }else
        {
            this.getScreen()
        }

        setTimeout(()=>this.show(),100)
    }
    
    componentDidMount()
    {
        if(!this.props.parent.isPreviz())
        {
            const screen=this.props.parent.getCurrentScreen()
            if(!screen){
                return false
            }
            this.screenId=screen.id
            var maxtime=this.props.parent.getScreenTime(screen.id)
            console.log("time",screen.id,maxtime)
            setTimeout(()=>{
                console.log("finish",screen.id)
                this.finish()
            },maxtime)
        }else
        {
            this.getScreen()
        }

        setTimeout(()=>this.show(),100)
    }

    componentWillUnmount()
    {
        if(this.finishTimeout){
            clearTimeout(this.finishTimeout)
        }
    }

    /*componentDidUpdate()
    {
        if(!this.props.parent.isPreviz() && this.screenId!=null && this.props.parent.getCurrentScreen().id!=this.screenId){
            this.init()
        }
    }*/


    async getScreen()
    {
        console.log("getScreens",this.props.id)

        const req=await fetch(BASE_URL+"/getAffiche.php?org="+localStorage.getItem("org")+"&id="+this.props.id)
        const res=await req.json()

        this.setState({previzReady:true,previzScreen:{data:res}})
    }

    finish()
    {
        if(this.state.needScroll && !this.state.finishScroll){
            setTimeout(()=>this.finish(),500)
            return false
        }
        
        console.log("FINISH")

        this.hide()

        setTimeout(()=>this.props.parent.next(),1500)
    }


    show()
    {
        console.log("show")
        
        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-cadre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=1
                elm.style.right="0"
            }
        },400)

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-text-animation")
            for(var elm of elms)
            {
                elm.style.opacity=1
            }
        },1000)
       
    }

    hide()
    {
        console.log("hide")

        var elms=document.getElementsByClassName("horaire-text-animation")
        for(var elm of elms)
        {
            elm.style.opacity=0
        }
 
        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-cadre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=0
                elm.style.right="50px"
            }
        },200)

    }

   
    render()
    {
        //console.log("PREVIZ TEXT")   
        var screen=null

        if(!this.props.parent.isPreviz())
        {
            screen=this.props.parent.getCurrentScreen()
            console.log(screen)
            
        }else
        {
            if(this.state.previzReady){
                screen=this.state.previzScreen
            }
        }
        
        console.log(screen)

        if(!screen){
            return null
        }
       
        switch(screen.data.design)
        {
            case "1":
                return <CustomDesign1 data={screen.data} parent={this} />
        }
        
    }
}