import { HebrewCalendar, Location } from "@hebcal/core"

export const BASE_URL="https://api.nechama.syn-assist.fr"
export const BASE_MEDIA_URL="https://media.nechama.syn-assist.fr/"

export const GETDATA_INTERVAL_TIME=1000*60*5

export const REFRESH_PLAQUE_INTERVAL_TIME=1000*10

export const VERSION_NAME="v1"

export function fontSize(ratio)
{
    return "calc("+ratio+"*0.1vh + "+ratio+"*0.1vw)"
}

export function generateGuid() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

var cachedData=[]

export async function cacheImage(url){
  var req=await fetch(url)
  if(req.status==200){
    const blob=await req.blob()
    cachedData.push({
      url:url,
      blob:URL.createObjectURL(blob)
    })
    //console.log(cachedData)
  }
}

export function getCachedImage(url)
{
  if(cachedData.findIndex(i=>i.url==url)!=-1){
    return cachedData.find(i=>i.url==url).blob
  }
  return url
}