import React from "react";
import { BASE_URL, fontSize, REFRESH_PLAQUE_INTERVAL_TIME } from "../config";
import {connect} from "react-redux"
import { getDateGematria, getDateNowFromZman, getDiffHDate, getHazkara, getVariableZman } from "../hebcalFunctions";
import AutoScroll from "../components/AutoScroll";
import hayomYom from "../data/hayomYom";
import { HDate } from "@hebcal/core";

export default class HayomYom extends React.Component{

    state={
        needScroll:false,
        finishScroll:false
    }

    portrait=(localStorage.getItem("portrait") === "true")


    componentDidMount()
    {
        setTimeout(()=>this.show(),500)
        
        if(!this.props.parent.isPreviz())
        {
            var maxtime=this.props.parent.getScreenTime(this.props.id)
            this.finishTimeout=setTimeout(()=>{
                this.finish()
            },maxtime)
        }
    }

    componentWillUnmount()
    {
        if(this.finishTimeout){
            clearTimeout(this.finishTimeout)
        }
    }

    finish()
    {
        if(this.state.needScroll && !this.state.finishScroll){
            setTimeout(()=>this.finish(),500)
            return false
        }

        console.log("FINISH")

        this.hide()

        setTimeout(()=>this.props.parent.next(),1500)
    }


    show()
    {
        var elms=document.getElementsByClassName("horaire-titre-animation")
        for(var elm of elms)
        {
            elm.style.opacity=1
            elm.style.right="0"
        }

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-bar-titre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=1
                elm.style.right="0"
            }
        },200)


        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-text-animation")
            for(var elm of elms)
            {
                elm.style.opacity=1
            }
        },1000)
       
    }

    hide()
    {
        var elms=document.getElementsByClassName("horaire-text-animation")
        for(var elm of elms)
        {
            elm.style.opacity=0
        }

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-bar-titre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=0
                elm.style.right="100px"
            }
        },400)

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-titre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=0
                elm.style.right="50px"
            }
        },600)
    }

   

    getData()
    {
        var hdate=new HDate(new Date())
        return hayomYom[hdate.getDate()+"-"+hdate.getMonth()]["fr"].replace(/(<br>)+/g, '\n')
    }
    

   
    render()
    {
        
        const text=this.getData()

        if(this.portrait)
        {
            return(
                <div style={{
                    display:"flex",
                    alignItems:"center",
                    flexDirection:"column",
                    width:"100vw",
                    marginTop:"1vh"
                }}>

                    <div style={{width:"100vw",display:"flex",alignItems:"center",flexDirection:"column"}}>
                        <span className="horaire-titre-animation" style={{fontFamily:"'Alice', serif",fontSize:fontSize(30)}}>Hayom Yom</span>
                        <div className="horaire-bar-titre-animation" style={{
                            height: "0.3vh",
                            width: "50vw",
                            borderRadius: "25px",
                            backgroundColor: "#000",
                            marginBottom: "3vh"
                        }}></div>
                    </div>

                    <AutoScroll
                        onNeedScroll={() => this.setState({needScroll: true})}
                        onFinishScroll={()=>this.setState({finishScroll:true})}
                        delay={15000} slow={30} style={{height:"73vh",width:"90vw",border:"0px solid red"}}>
                        <span className="horaire-text-animation" style={{fontFamily:"Alice, serif",fontSize:fontSize(17),whiteSpace:"pre-line"}}>{text}</span>
                    </AutoScroll>

                </div>
            )
        }

        return(
            <div style={{
                display:"flex",
                alignItems:"center",
                flexDirection:"column",
                width:"100vw",
            }}>

                <div style={{width:"100vw",display:"flex",alignItems:"center",flexDirection:"column"}}>
                    <span className="horaire-titre-animation" style={{fontFamily:"'Alice', serif",fontSize:fontSize(25)}}>Hayom Yom</span>
                    <div className="horaire-bar-titre-animation" style={{height:"0.5vh",width:"20vw",borderRadius:"25px",backgroundColor:"#000",marginBottom:"5vh"}}></div>
                </div>

                <AutoScroll
                onNeedScroll={()=>this.setState({needScroll:true})} 
                onFinishScroll={()=>this.setState({finishScroll:true})} 
                 delay={15000} slow={70} style={{height:"65vh",width:"90vw",border:"0px solid red"}}>
                    <span className="horaire-text-animation" style={{fontFamily:"Alice, serif",fontSize:fontSize(17),whiteSpace:"pre-line"}}>{text}</span>
                </AutoScroll>
        
            </div>
        )
    }
}