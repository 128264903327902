import React from "react";
import { BASE_URL, fontSize, REFRESH_PLAQUE_INTERVAL_TIME } from "../config";
import {connect} from "react-redux"
import { getDateGematria, getDateNowFromZman, getDiffHDate, getHazkara, getVariableZman } from "../hebcalFunctions";
import AutoScroll from "../components/AutoScroll";
import Activity from "../components/Activity";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

class Horaire extends React.Component{

    state={
        ready:false,
        needScroll:false,
        finishScroll:false,
        horaire:[]
    }

    portrait=(localStorage.getItem("portrait") === "true")


    componentDidMount()
    {
        this.getData(true)
        

        if(!this.props.parent.isPreviz())
        {
            var maxtime=this.props.parent.getScreenTime(this.props.id)
            this.finishTimeout=setTimeout(()=>{
                this.finish()
            },maxtime)
        }

    }
    
    componentWillUnmount()
    {
        if(this.finishTimeout){
            clearTimeout(this.finishTimeout)
        }
    }

    finish()
    {
        if(this.state.needScroll && !this.state.finishScroll){
            setTimeout(()=>this.finish(),500)
            return false
        }

        console.log("FINISH")

        this.hide()

        setTimeout(()=>this.props.parent.next(),1500)
    }

    async getData(init=false)
    {
        console.log("GET HORAIRE")

        try{
            const req=await fetch(BASE_URL+"/getHorairev2.php?org="+localStorage.getItem("org"))
            const res=await req.json()

            this.setState({horaire:res})

            if(init)
            {
                setTimeout(()=>this.show(),500)
            }

            if(!this.state.ready){
                this.setState({ready:true})
            }
        }catch(e)
        {
            console.error(e)
            setTimeout(()=>this.getData(init),100)
        }
    }


    show()
    {
        var elms=document.getElementsByClassName("horaire-titre-animation")
        for(var elm of elms)
        {
            elm.style.opacity=1
            elm.style.right="0"
        }

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-bar-titre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=1
                elm.style.right="0"
            }
        },200)

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-cadre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=1
                elm.style.right="0"
            }
        },400)

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-text-animation")
            for(var elm of elms)
            {
                elm.style.opacity=1
            }
        },1000)
       
    }

    hide()
    {
        var elms=document.getElementsByClassName("horaire-text-animation")
        for(var elm of elms)
        {
            elm.style.opacity=0
        }
 
        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-cadre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=0
                elm.style.right="50px"
            }
        },200)

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-bar-titre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=0
                elm.style.right="100px"
            }
        },400)

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-titre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=0
                elm.style.right="50px"
            }
        },600)
    }

    getNextDay(number,date)
    {
        while(date.getDay()!=number)
        {
            date.setDate(date.getDate()+1)
        }

        return date
    }

    getHoraire(type)
    {
        const horaire=this.state.horaire

        var date=new Date()

        if(type=="CHABBAT_SOIR"){
            date=this.getNextDay(5,date)
        }

        if(type=="CHABBAT_MATIN"){
            date=this.getNextDay(6,date)
        }

        var data=[]

        for(var h of horaire)
        {
            if(h.type!=type){
                continue
            }

            if(h.variable=="1")
            {
                
                var time=new Date(getVariableZman(this.props.config,h.variable_param,date)?.getTime())

                if(typeof time !== '[object Date]'){
                    continue
                }

                if(h.avant=="1"){
                    time.setMinutes(time.getMinutes()-parseInt(h.horaire_variable))
                }else{
                    time.setMinutes(time.getMinutes()+parseInt(h.horaire_variable))
                }

                time =("0" + time.getHours()).slice(-2)+":"+("0" + time.getMinutes()).slice(-2)
                
                data.push({
                    nom:h.nom,
                    time:time
                })
            }else
            {
                data.push({
                    nom:h.nom,
                    time:h.horaire_fixe
                })
            }
        }

        data.sort(function(a,b){
            const ah=parseInt(a.time.split(":")[0])
            const am=parseInt(a.time.split(":")[1])
            const bh=parseInt(b.time.split(":")[0])
            const bm=parseInt(b.time.split(":")[1])

            var datea=new Date()
            datea.setHours(ah,am)

            var dateb=new Date()
            dateb.setHours(bh,bm)

            if(datea==dateb){
                return 0
            }
            return datea<dateb?-1:1
        })

        return data
    }


    renderIndicator=(clickHandler,isSelected,index,label)=>
    {
        return(
            <div style={{
                    display:"inline-block",
                    borderRadius:"100%",
                    height:"2vh",
                    width:"2vh",
                    marginLeft:"2vh",
                    marginRight:"2vh",
                    backgroundColor:isSelected?"black":"grey",
                }}>

            </div>
        )
    }

   
    render()
    {
        if(this.state.horaire.length==0 && !this.state.ready)
        {
            return(
                <Activity/>
            )
        }

        if(this.state.horaire.length==0)
        {
            return null
        }

        const horaireDimanche=this.getHoraire("DIMANCHE")
        const horaireSemaine=this.getHoraire("SEMAINE")
        const horaireChabbatSoir=this.getHoraire("CHABBAT_SOIR")
        const horaireChabbatMatin=this.getHoraire("CHABBAT_MATIN")


        console.log(horaireDimanche.length > 0)

        if(this.portrait)
        {
            let items=[]
            let renderDimanche=(
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100vw",paddingBottom:"10vh"}}>
                    <div className="horaire-cadre-animation" style={{
                        display: "flex",
                        height: "65vh",
                        width: "90vw",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "0px solid red",

                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "65vh",
                            width: "90vw",
                            alignItems: "center",
                            border: "0px solid green",
                        }}>

                                <span className="horaire-titre-animation" style={{
                                    fontFamily: "'Lora', serif",
                                    fontSize: fontSize(30),
                                    marginBottom: "3vh",
                                    marginTop:"1vh",
                                }}>Dimanche</span>


                            <AutoScroll
                                onNeedScroll={() => this.setState({needScroll: true})}
                                onFinishScroll={() => this.setState({finishScroll: true})}
                                style={{
                                    width: "90vw",
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "column"
                                }}>
                                {
                                    horaireDimanche.map((item, index) => (
                                        <div key={index} className="horaire-text-animation" style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "100%",
                                            paddingLeft: "10%",
                                            paddingRight: "10%",
                                            marginBottom: "2vh"
                                        }}>
                                                <span style={{
                                                    fontFamily: "Bodoni FLF",
                                                    fontSize: fontSize(15)
                                                }}>{item.nom}</span>
                                            <span style={{
                                                fontFamily: "Bodoni FLF",
                                                fontSize: fontSize(15)
                                            }}>{item.time}</span>
                                        </div>
                                    ))
                                }

                            </AutoScroll>

                        </div>

                    </div>
                </div>
            )
            if(horaireDimanche.length > 0){
                items.push(renderDimanche)
            }

            console.log("renderDimanche",renderDimanche)

            return (
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "100vw",
                }}>

                    <div style={{
                        width: "100vw",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        marginTop: "2vh"
                    }}>
                        <span className="horaire-titre-animation"
                              style={{fontFamily: "'Alice', serif", fontSize: fontSize(25)}}>Minyanim</span>
                        <div className="horaire-bar-titre-animation" style={{
                            height: "0.3vh",
                            width: "40vw",
                            borderRadius: "25px",
                            backgroundColor: "#000"
                        }}></div>
                    </div>


                    <div style={{marginTop: "5vh"}}>
                        <Carousel
                            showThumbs={false}
                            showArrows={false}
                            swipeable={false}
                            showStatus={false}
                            renderIndicator={this.renderIndicator}
                            autoPlay
                            interval={8000}
                            infiniteLoop
                            onChange={(index) => {
                                if (index === 0) {
                                    if (!this.props.parent.isPreviz()) {
                                        this.finish()
                                    }
                                }
                            }}
                            width={"100vw"}
                        >

                            {items.map(r=>(r))}

                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100vw",
                                paddingBottom:"5vh"
                            }}>
                                <div className="horaire-cadre-animation" style={{
                                    display: "flex",
                                    height: "65vh",
                                    width: "90vw",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: "0px solid red",
                                }}>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        height: "65vh",
                                        width: "90vw",
                                        alignItems: "center",
                                        border: "0px solid green",
                                    }}>

                                    <span className="horaire-titre-animation" style={{
                                        fontFamily: "'Lora', serif",
                                        fontSize: fontSize(30),
                                        marginBottom: "3vh",
                                        marginTop: "2vh",
                                    }}>Semaine</span>


                                        <AutoScroll
                                            onNeedScroll={() => this.setState({needScroll: true})}
                                            onFinishScroll={() => this.setState({finishScroll: true})}
                                            style={{
                                                width: "90vw",
                                                display: "flex",
                                                alignItems: "center",
                                                flexDirection: "column"
                                            }}>
                                        {
                                            horaireSemaine.map((item, index) => (
                                                <div key={index} className="horaire-text-animation" style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    width: "100%",
                                                    paddingLeft: "10%",
                                                    paddingRight: "10%",
                                                    marginBottom: "2vh"
                                                }}>
                                                    <span style={{
                                                        fontFamily: "Bodoni FLF",
                                                        fontSize: fontSize(15)
                                                    }}>{item.nom}</span>
                                                    <span style={{
                                                        fontFamily: "Bodoni FLF",
                                                        fontSize: fontSize(15)
                                                    }}>{item.time}</span>
                                                </div>
                                            ))
                                        }

                                    </AutoScroll>

                                </div>

                            </div>
                            </div>



                            <div style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100vw",paddingBottom:"5vh"}}>


                            <div className="horaire-cadre-animation" style={{
                                display: "flex",
                                height: "65vh",
                                width: "90vw",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "0px solid red",

                            }}>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    height: "65vh",
                                    width: "90vw",
                                    alignItems: "center",
                                    border: "0px solid green",
                                }}>

                                    <span className="horaire-titre-animation" style={{
                                        fontFamily: "'Lora', serif",
                                        fontSize: fontSize(30),
                                        marginBottom: "3vh",
                                        marginTop: "2vh",
                                    }}>Chabath</span>

                                    <AutoScroll
                                        onNeedScroll={() => this.setState({needScroll: true})}
                                        onFinishScroll={() => this.setState({finishScroll: true})}
                                        slow={30} delay={5000} style={{
                                        width: "75vw",
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column"
                                    }}>

                                        {
                                            horaireChabbatSoir.map((item, index) => (
                                                <div key={index} className="horaire-text-animation" style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    width: "100%",
                                                    paddingLeft: "10%",
                                                    paddingRight: "10%",
                                                    marginBottom: "2vh"
                                                }}>
                                                    <span style={{
                                                        fontFamily: "Bodoni FLF",
                                                        fontSize: fontSize(15)
                                                    }}>{item.nom}</span>
                                                    <span style={{
                                                        fontFamily: "Bodoni FLF",
                                                        fontSize: fontSize(15)
                                                    }}>{item.time}</span>
                                                </div>
                                            ))
                                        }

                                        {
                                            horaireChabbatMatin.map((item, index) => (
                                                <div key={index} className="horaire-text-animation" style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    width: "100%",
                                                    paddingLeft: "0%",
                                                    paddingRight: "0%",
                                                    marginBottom: "2vh",
                                                    paddingTop: index == 0 ? "3vh" : 0,
                                                    marginTop: index == 0 ? "1vh" : 0,
                                                    borderTop: index == 0 ? "0.3vh solid #000" : 0,
                                                }}>
                                                    <span style={{
                                                        fontFamily: "Bodoni FLF",
                                                        fontSize: fontSize(15)
                                                    }}>{item.nom}</span>
                                                    <span style={{
                                                        fontFamily: "Bodoni FLF",
                                                        fontSize: fontSize(15)
                                                    }}>{item.time}</span>
                                                </div>
                                            ))
                                        }

                                    </AutoScroll>

                                </div>

                            </div>
                            </div>


                        </Carousel>
                    </div>
                </div>
            )
        }

        return (
            <div style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "100vw",
            }}>

                <div style={{width: "100vw", display: "flex", alignItems: "center", flexDirection: "column"}}>
                    <span className="horaire-titre-animation"
                          style={{fontFamily: "'Alice', serif", fontSize: fontSize(25)}}>Minyanim</span>
                    <div className="horaire-bar-titre-animation"
                         style={{height: "0.5vh", width: "20vw", borderRadius: "25px", backgroundColor: "#000"}}></div>
                </div>

                <div style={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "row",
                    width: "100vw",
                    marginTop: "1vh"
                }}>


                    {horaireDimanche.length > 0 && (
                        <div className="horaire-cadre-animation" style={{
                            backgroundImage: "url(/images/cadre-5.png)",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            display: "flex",
                            height: "70vh",
                            width: "65vh",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "0px solid red",

                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "60vh",
                                width: "59vh",
                                alignItems: "center",
                                border: "0px solid green",
                            }}>

                                <span className="horaire-titre-animation" style={{
                                    fontFamily: "'Lora', serif",
                                    fontSize: fontSize(25),
                                    marginBottom: "3vh"
                                }}>Dimanche</span>


                                <AutoScroll
                                    onNeedScroll={() => this.setState({needScroll: true})}
                                    onFinishScroll={() => this.setState({finishScroll: true})}
                                    style={{
                                        width: "32vw",
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column"
                                    }}>
                                    {
                                        horaireDimanche.map((item, index) => (
                                            <div key={index} className="horaire-text-animation" style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "100%",
                                                paddingLeft: "10%",
                                                paddingRight: "10%",
                                                marginBottom: "2vh"
                                            }}>
                                                <span style={{
                                                    fontFamily: "Bodoni FLF",
                                                    fontSize: fontSize(13)
                                                }}>{item.nom}</span>
                                                <span style={{
                                                    fontFamily: "Bodoni FLF",
                                                    fontSize: fontSize(13)
                                                }}>{item.time}</span>
                                            </div>
                                        ))
                                    }

                                </AutoScroll>

                            </div>

                        </div>
                    )}


                    <div className="horaire-cadre-animation" style={{
                        backgroundImage: "url(/images/cadre-5.png)",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        display: "flex",
                        height: "70vh",
                        width: "65vh",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "0px solid red",

                    }}>

                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "60vh",
                            width: "59vh",
                            alignItems: "center",
                            border: "0px solid green",
                        }}>

                        <span className="horaire-titre-animation" style={{fontFamily:"'Lora', serif",fontSize:fontSize(25),marginBottom:"3vh"}}>Semaine</span>


                        <AutoScroll 
                        onNeedScroll={()=>this.setState({needScroll:true})} 
                        onFinishScroll={()=>this.setState({finishScroll:true})}
                        style={{width:"32vw",display:"flex",alignItems:"center",flexDirection:"column"}}>
                                {
                                    horaireSemaine.map((item,index)=>(
                                        <div key={index} className="horaire-text-animation" style={{
                                            display:"flex",
                                            justifyContent:"space-between",
                                            width:"100%",
                                            paddingLeft:"10%",
                                            paddingRight:"10%",
                                            marginBottom:"2vh"
                                        }}>
                                            <span style={{fontFamily:"Bodoni FLF",fontSize:fontSize(13)}} >{item.nom}</span>
                                            <span style={{fontFamily:"Bodoni FLF",fontSize:fontSize(13)}} >{item.time}</span>
                                        </div>
                                    ))
                                }
                                
                        </AutoScroll> 
                         
                    </div>
                    
                </div>


                    <div className="horaire-cadre-animation" style={{
                        backgroundImage:"url(/images/cadre-5.png)",
                        backgroundSize:"contain",
                        backgroundRepeat:"no-repeat",
                        display:"flex",
                        height:"70vh",
                        width:"65vh",
                        justifyContent:"center",
                        alignItems:"center",
                        border:"0px solid red",
                        
                    }}>

                        <div style={{
                            display:"flex",
                            flexDirection:"column",
                            height:"60vh",
                            width:"59vh",
                            alignItems:"center",
                            border:"0px solid green",
                        }}>

                        <span className="horaire-titre-animation" style={{fontFamily:"'Lora', serif",fontSize:fontSize(25),marginBottom:"3vh"}}>Chabath</span>

                            <AutoScroll 
                            onNeedScroll={()=>this.setState({needScroll:true})} 
                            onFinishScroll={()=>this.setState({finishScroll:true})} 
                            slow={30} delay={5000} style={{width:"59vh",display:"flex",alignItems:"center",flexDirection:"column"}}>
                                   
                                    {
                                        horaireChabbatSoir.map((item,index)=>(
                                            <div key={index} className="horaire-text-animation" style={{
                                                display:"flex",
                                                justifyContent:"space-between",
                                                width:"100%",
                                                paddingLeft:"10%",
                                                paddingRight:"10%",
                                                marginBottom:"2vh"
                                            }}>
                                                <span style={{fontFamily:"Bodoni FLF",fontSize:fontSize(13)}} >{item.nom}</span>
                                                <span style={{fontFamily:"Bodoni FLF",fontSize:fontSize(13)}} >{item.time}</span>
                                            </div>
                                        ))
                                    }

                                    {
                                        horaireChabbatMatin.map((item,index)=>(
                                            <div key={index} className="horaire-text-animation" style={{
                                                display:"flex",
                                                justifyContent:"space-between",
                                                width:"100%",
                                                paddingLeft:"10%",
                                                paddingRight:"10%",
                                                marginBottom:"2vh",
                                                paddingTop:index==0?"3vh":0,
                                                marginTop:index==0?"1vh":0,
                                                borderTop:index==0?"0.5vh solid #000":0,
                                            }}>
                                                <span style={{fontFamily:"Bodoni FLF",fontSize:fontSize(13)}} >{item.nom}</span>
                                                <span style={{fontFamily:"Bodoni FLF",fontSize:fontSize(13)}} >{item.time}</span>
                                            </div>
                                        ))
                                    }

                                    
                            </AutoScroll> 
                            
                        </div>
                        
                    </div>

                    
                
                </div>
        
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
      horaire:state.horaire,
      config:state.config
    };
  };

export default connect(mapStateToProps)(Horaire)