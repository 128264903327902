import React from "react";
import { BASE_MEDIA_URL, fontSize, REFRESH_PLAQUE_INTERVAL_TIME } from "../config";


export default class CustomVideo extends React.Component{
    
    componentDidMount()
    {
        if(!this.props.parent.isPreviz())
        {
            const screen=this.props.parent.getCurrentScreen()
            if(!screen){
                return false
            }
            
            /*
            var maxtime=this.props.parent.getScreenTime(screen.id)
            setTimeout(()=>{
                this.finish()
            },maxtime)
            */
        }
    }

    finish()
    {
        console.log("FINISH")

        this.props.parent.next()
    }

   
    render()
    {
        const screen=this.props.parent.getScreen(this.props.id)
        console.log(screen)
        if(!screen){
            return null
        }

        let videoUrl=screen.data.video

        if(!videoUrl.startsWith("http")){
            videoUrl=BASE_MEDIA_URL+videoUrl
        }

        return(
            <div style={{
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                backgroundColor:"#000"
            }}>
                <video src={videoUrl} style={{height:"100vh"}} onEnded={()=>this.finish()} autoPlay muted playsInline></video>
            </div>
            
        )
    }
}