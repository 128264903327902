import React from "react";
import MediumPlaque from "../components/MediumPlaque";
import { REFRESH_PLAQUE_INTERVAL_TIME } from "../config";


export default class Mini3x3 extends React.Component{

    constructor(props)
    {
        super(props)
        this.candlePlay=[]
    }

    state={
        offset:0,
        plaqueVisible:false
    }

    timeout=[]

    getMinis()
    {
        const data=this.props.data

        if(data.other.length<=12)
        {
            return data.other

        }else
        {
        
            if((this.state.offset+12)>=data.other.length)
            {
                this.setState({offset:0})
            }

            return data.other.slice(this.state.offset,this.state.offset+12)
            
        }
    }

    componentDidMount()
    {
        this.tour=1

        this.setState({plaqueVisible:true})

        this.intervalPlaque= setInterval(()=>this.refreshPlaques(),REFRESH_PLAQUE_INTERVAL_TIME)
    }

    componentWillUnmount()
    {
        clearInterval(this.intervalPlaque)
        for(var t of this.timeout)
        {
            if(t){
                clearTimeout(t)
            }
        }
    }

    refreshPlaques()
    {
        if(this.props.data.other.length<=12 && this.tour<100)
        {
            return false
        }

        this.setState({plaqueVisible:false},()=>{

            this.timeout.push(setTimeout(()=>{
                if((this.state.offset+12)>this.props.data.other.length)
                {
                    this.tour=1
                    this.props.parent.getNichmat()
                }

                this.setState({plaqueVisible:true,offset:this.state.offset+1})

            },1000))
        })
    }

    onCandlePlay(id)
    {
        this.candlePlay.push(id)

        if(this.candlePlay.length>=12*2){
            console.log("SHOW NER")
            this.props.show()
        }
    }

    render()
    {
        console.log("MINIS 3X3")
        const minis=this.getMinis()

        return(
            <div style={{
                display:"grid",
                gridTemplateColumns:"25vw 25vw 25vw",
                gridGap: "1.4vw",
                rowGap:"1vh"
            }}>

            {
                minis.map((item,index)=><MediumPlaque key={index} onCandlePlay={(id)=>this.onCandlePlay(id)} item={item} visible={this.state.plaqueVisible}/>)
            }
                
            </div>
        )
    }
}