import React from "react";

import { fontSize } from "../config";

import "react-activity/dist/library.css";

import { Spinner } from "react-activity";

export default class Activity extends React.Component{


    render()
    {
        return null
        
        return(
            <div style={{width:"inherit",height:"inherit",display:"flex",alignItems:"center",justifyContent:"center",border:"0px solid black"}}>
                <Spinner color="#000" size={150}/>
            </div>
        )
    }
}