import React from "react";
import { Button } from "react-bootstrap";



export default class RequestOrg extends React.Component{


    orgID=null

    render()
    {
        return(
            <div style={{
                display:"flex",
                flex:1,
                height:"80vh",
                marginTop:"100px",
                justifyContent:"center",
                alignItems:"center"
            }}>

                <div style={{
                    backgroundColor:"white",
                    borderRadius:"20px",
                    width:"40%",
                    height:"40%",
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center",
                    justifyContent:"center"
                }}>

                    <h3>Veuillez Choisir un identifiant d'organisation :</h3>

                    <input 
                    style={{height:"50px",fontSize:"25px",marginTop:"30px"}} 
                    type="number" 
                    placeholder="Organisation" 
                    onChange={(e)=>this.orgID=e.target.value} />

                    <Button 
                    className="btn-success mt-3"
                     onClick={()=>{
                        var orgId=null
                        if(orgId=parseInt(this.orgID))
                        {
                            localStorage.setItem("org",this.orgID)
                            this.props.onValidate()
                        }
                    }}>Valider</Button>
                
                </div>
            
            </div>
        )
    }
}