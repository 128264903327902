import React from "react";
import BigPlaque from "../components/BigPlaque";
import MiniPlaque from "../components/MiniPlaque";
import { REFRESH_PLAQUE_INTERVAL_TIME } from "../config";


export default class Big extends React.Component{

    state={
        offsetMini:0,
        plaqueVisibleMini:false,

        offsetBig:0,
        plaqueVisibleBig:false,
    }

    timeout=[]

    getBig()
    {
        const data=this.props.data

        if(data.today.length<=1)
        {
            return data.today

        }else
        {
        
            if((this.state.offsetBig+1)>=data.today.length)
            {
                this.setState({offsetBig:0})
            }

            return data.today.slice(this.state.offsetBig,this.state.offsetBig+1)
            
        }
    }

    getMinis()
    {
        const data=this.props.data

        if(data.other.length<=16)
        {
            return data.other

        }else
        {
        
            if((this.state.offsetMini+16)>=data.other.length)
            {
                this.setState({offsetMini:0})
            }

            return data.other.slice(this.state.offsetMini,this.state.offsetMini+16)
            
        }
    }

    componentDidMount()
    {
        this.tour=1

        this.setState({plaqueVisibleMini:true,plaqueVisibleBig:true})

        this.intervalPlaqueBig=setInterval(()=>this.refreshPlaquesBig(),REFRESH_PLAQUE_INTERVAL_TIME)
        this.intervalPlaqueMini=setInterval(()=>this.refreshPlaquesMini(),REFRESH_PLAQUE_INTERVAL_TIME)
    }

    componentWillUnmount()
    {
        clearInterval(this.intervalPlaqueBig)
        clearInterval(this.intervalPlaqueMini)

        for(var t of this.timeout)
        {
            if(t){
                clearTimeout(t)
            }
        }
    }


    refreshPlaquesBig()
    {
        if(this.props.data.today.length<=1)
        {
            return false
        }

        this.setState({plaqueVisibleBig:false},()=>{

            this.timeout.push(setTimeout(()=>{
               
                this.setState({plaqueVisibleBig:true,offsetBig:this.state.offsetBig+1})

            },1000))
        })
    }

    refreshPlaquesMini()
    {
        if(this.props.data.other.length<=16 && this.tour<100)
        {
            return false
        }

        this.setState({plaqueVisibleMini:false},()=>{

            this.timeout.push(setTimeout(()=>{
                if((this.state.offsetMini+16)>this.props.data.other.length)
                {
                    this.tour=1
                    this.props.parent.getNichmat()
                }

                this.setState({plaqueVisibleMini:true,offsetMini:this.state.offsetMini+1})

            },1000))
        })
    }

    onCandlePlay(id)
    {
        this.candlePlay.push(id)

        if(this.candlePlay.length>=17*2){
            console.log("SHOW NER")
            this.props.show()
        }
    }

    render()
    {
        console.log("Big")
        
        const big=this.getBig()
        const minis=this.getMinis()

        console.log("big",big)
        console.log("minis",minis)
        
   
        const minis1=minis.slice(0,5)
        const minis2=minis.slice(5,8)
        const minis3=minis.slice(8,11)
        const minis4=minis.slice(-5)


        return(
            <div style={{
                paddingTop:"1vh",
                paddingBottom:"1vh",
                paddingRight:"2vw",
                paddingLeft:"2vw",
                width:"100vw",
            }}>

                <div style={{
                    display:"flex",
                    flexDirection:"row",
                    justifyContent:"space-between",
                    marginBottom:"1vh",
                    border:"0px solid red"
                }}>
                {
                    minis1.map((item,index)=><MiniPlaque key={index} onCandlePlay={(id)=>this.onCandlePlay(id)} item={item} visible={this.state.plaqueVisibleMini}/>)
                }
                </div>

                <div style={{
                    display:"flex",
                    flexDirection:"row",
                    border:"0px solid green",
                    justifyContent:"space-between"
                }}>
                    <div style={{
                        display:"flex",
                        flexDirection:"column",
                        justifyContent:"space-between",
                        border:"0px solid blue"
                    }}>
                        {
                            minis2.map((item,index)=><MiniPlaque key={index} onCandlePlay={(id)=>this.onCandlePlay(id)} item={item} visible={this.state.plaqueVisibleMini}/>)
                        }
                    </div>

                    <BigPlaque item={big[this.state.offsetBig]} onCandlePlay={(id)=>this.onCandlePlay(id)} visible={this.state.plaqueVisibleBig}/>

                    <div style={{
                        display:"flex",
                        flexDirection:"column",
                        justifyContent:"space-between",
                        border:"0px solid blue"
                    }}>
                        {
                            minis3.map((item,index)=><MiniPlaque key={index} onCandlePlay={(id)=>this.onCandlePlay(id)} item={item} visible={this.state.plaqueVisibleMini}/>)
                        }
                    </div>

                </div>

                <div style={{
                    display:"flex",
                    flexDirection:"row",
                    justifyContent:"space-between",
                    marginTop:"1vh",
                    border:"0px solid red"
                }}>
                {
                    minis4.map((item,index)=><MiniPlaque key={index} onCandlePlay={(id)=>this.onCandlePlay(id)} item={item} visible={this.state.plaqueVisibleMini}/>)
                }
                </div>
            
                
            </div>
        )
    }
}