import React from "react";
import { BASE_URL, fontSize, REFRESH_PLAQUE_INTERVAL_TIME } from "../config";
import {connect} from "react-redux"
import { getDateGematria, getDateNowFromZman, getDiffHDate, getHazkara, getHebCalByDate, getMolad, getNextRoshHosdesh, getVariableZman } from "../hebcalFunctions";
import { HDate } from "@hebcal/core";


class Molad extends React.Component{

    componentDidMount()
    {
        setTimeout(()=>this.show(),500)

        if(!this.props.parent.isPreviz())
        {
            var maxtime=this.props.parent.getScreenTime(this.props.id)
           this.finishTimeout=setTimeout(()=>{
                this.finish()
            },maxtime)
        }
    }

    componentWillUnmount()
    {
        if(this.finishTimeout){
            clearTimeout(this.finishTimeout)
        }
    }

    HebMonth=["","ניסן","אייר","סיון","תמוז","מנחם אב","אלול","תישרי","מר חשון","כיסלו","טבת","שבת","שבת","אדר"]
    HebMonthDouble=["","ניסן","אייר","סיון","תמוז","מנחם אב","אלול","תישרי","מר חשון","כיסלו","טבת","שבת","שבת","אדר א","אדר ב"]

    HebDays=["הראשון","השני","השלישי","הרביעי","החמישי","השישי","השבת"]

    finish()
    {
        console.log("FINISH")
        this.hide()

        setTimeout(()=>this.props.parent.next(),1500)
    }

    show()
    {
        var elms=document.getElementsByClassName("horaire-text-animation")
        for(var elm of elms)
        {
            elm.style.opacity=1
        }
       
    }

    hide()
    {
        var elms=document.getElementsByClassName("horaire-text-animation")
        for(var elm of elms)
        {
            elm.style.opacity=0
        }
    }

    getData()
    {
        const roshhodesh=getNextRoshHosdesh(new Date())
        if(roshhodesh.length==0){
            //TODO erreur => next screens
            return null
        }
        console.log("roshhodesh",roshhodesh)
        
        const molad=getMolad(roshhodesh[roshhodesh.length-1].hdate.getMonthName())
        console.log("molad",molad)

        if(molad==null){
            //TODO erreur => next screens
            return null
        }

        return {molad,roshhodesh}
    }
    

   
    render()
    {
        
        const data=this.getData()
        console.log(data)

        if(!data){
            return null
        }

        var hebMonthName=null
        if(data.roshhodesh[data.roshhodesh.length-1].hdate.isLeapYear)
        {
             hebMonthName=this.HebMonthDouble[data.roshhodesh[data.roshhodesh.length-1].hdate.getMonth()]
        }else
        {
             hebMonthName=this.HebMonth[data.roshhodesh[data.roshhodesh.length-1].hdate.getMonth()]
        }


    
        return(
            <div style={{
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                flexDirection:"column",
                width:"100vw",
               
            }}>

            <div className="horaire-text-animation" style={{position:"relative",height:"75vh",width:"75vw",color:"#FFF",fontFamily:"Frank Ruhl Libre",border:"0px solid red"}}>

                <img src="/images/molad.jpeg" style={{position:"absolute",height:"inherit",zIndex:0}}/>


                <span style={{position:"absolute",zIndex:1,fontSize:fontSize(20),top:"13vh",left:"27vh"}}>{hebMonthName}</span>
                <span style={{position:"absolute",zIndex:1,fontSize:fontSize(20),top:"20vh",left:"32vh"}}>{this.HebDays[data.molad.dow].replace("ה","")}</span>
                <span style={{position:"absolute",zIndex:1,fontSize:fontSize(20),top:"27vh",left:"39vh"}}>{data.molad.hour}</span>

                <span style={{position:"absolute",zIndex:1,fontSize:fontSize(20),top:"35vh",left:"50vh"}}>{data.molad.minutes}</span>

                <span style={{position:"absolute",zIndex:1,fontSize:fontSize(20),top:"42vh",left:"50vh"}}>{data.molad.chalakim}</span>

                <span style={{position:"absolute",zIndex:1,fontSize:fontSize(20),top:"49vh",left:"30vh"}}>{hebMonthName}</span>


                <span style={{position:"absolute",zIndex:1,fontSize:fontSize(20),top:"56vh",left:"65vh"}}>{this.HebDays[data.roshhodesh[0].hdate.getDay()]}</span>
                
                {data.roshhodesh.length>1 && <span style={{position:"absolute",zIndex:1,fontSize:fontSize(20),top:"56vh",left:"12vh"}}>{this.HebDays[data.roshhodesh[1].hdate.getDay()]}</span>}

                {data.roshhodesh.length==1 &&<div style={{position:"absolute",backgroundColor:"rgb(20,20,20)",width:"12vw",height:"10vh",border:"0px solid red",zIndex:1,top:"56.5vh",left:"29vh"}}></div>}
            

            </div>
                
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
      org:state.org,
    };
  };

export default connect(mapStateToProps)(Molad)