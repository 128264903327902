import React from "react";

import { fontSize } from "../config";
import AutoScroll from "./AutoScroll";


export default class CustomDesign1 extends React.Component{

    portrait=(localStorage.getItem("portrait") === "true")


    render()
    {
        const data=this.props.data
        const parent=this.props.parent


        if(this.portrait)
        {
            return(
                <div style={{width:"inherit",height:"inherit",display:"flex",alignItems:"center",justifyContent:"center",border:"0px solid blue"}}>
                    <div className="horaire-cadre-animation" style={{
                        display:"flex",
                        height:"70vh",
                        width:"100vw",
                        justifyContent:"center",
                        alignItems:"center",
                    }}>

                        <div style={{
                            display:"flex",
                            flexDirection:"column",
                            height:"65vh",
                            border:"0px solid red",
                        }}>

                            <AutoScroll
                                onNeedScroll={()=>{
                                    console.log("NEED SCROLL")
                                    parent.setState({needScroll:true})
                                }}
                                onFinishScroll={()=>parent.setState({finishScroll:true})}
                                delay={2000} slow={30}
                                style={{width:"90vw",display:"flex",alignItems:"center",flexDirection:"column",border:"0px solid red"}}
                                html={data.text.replaceAll("px;","vw;")}
                            ></AutoScroll>
                        </div>
                    </div>
                </div>
            )
        }


        return(
            <div style={{width:"inherit",height:"inherit",display:"flex",alignItems:"center",justifyContent:"center",border:"0px solid blue"}}>
                <div className="horaire-cadre-animation" style={{
                    backgroundImage:"url(/images/cadre-4.png)",
                    backgroundSize:"contain",
                    backgroundRepeat:"no-repeat",
                    display:"flex",
                    height:"80vh",
                    width:"130vh",
                    justifyContent:"center",
                    alignItems:"center",
                }}>

                    <div style={{
                        display:"flex",
                        flexDirection:"column",
                        height:"65vh",
                        border:"0px solid red",
                    }}>

                        <AutoScroll 
                            onNeedScroll={()=>{
                                console.log("NEED SCROLL")
                                parent.setState({needScroll:true})
                            }} 
                            onFinishScroll={()=>parent.setState({finishScroll:true})}
                            delay={2000} slow={30}
                            style={{width:"65vw",display:"flex",alignItems:"center",flexDirection:"column",border:"0px solid red"}}
                            html={data.text.replaceAll("px;","vh;")}
                        ></AutoScroll>
                    </div>
                </div>
            </div>
        )
    }
}