import React from "react";
import MiniPlaque from "../components/MiniPlaque";
import { BASE_URL, REFRESH_PLAQUE_INTERVAL_TIME } from "../config";
import {connect} from "react-redux"
import { getDateGematria, getDateNowFromZman, getDiffHDate, getHazkara } from "../hebcalFunctions";
import { HDate } from "@hebcal/core";
import Mini from "./Mini";
import Big from "./Big";
import Activity from "../components/Activity";
import Mini3x3 from "./Mini3x3";

class Ner extends React.Component{

    state={
        ready:false
    }

    portrait=(localStorage.getItem("portrait") === "true")


    componentDidMount()
    {
        if(this.portrait){
            this.finish()
        }

        this.getNichmat()
        this.interval=setInterval(()=>this.getNichmat(),10*60*1000)

        setTimeout(()=>this.show(),1000)

        if(!this.props.parent.isPreviz())
        {
            var maxtime=this.props.parent.getScreenTime(this.props.id)

            if(!this.props.parent.isInfinite()){
                this.finishTimeout=setTimeout(()=>{
                    this.finish()
                },maxtime)
            }
        }

    }

    componentWillUnmount()
    {
        if(this.interval){
            clearInterval(this.interval)
        }

        if(this.finishTimeout){
            clearTimeout(this.finishTimeout)
        }
    }

    finish()
    {
        console.log("FINISH")

        this.hide()

        setTimeout(()=>this.props.parent.next(),1500)
    }

    async getNichmat()
    {
        console.log("GET NICHMAT")
        try
        {
            const req=await fetch(BASE_URL+"/getNichmat.php?org="+localStorage.getItem("org"))
            const res=await req.json()

            this.props.dispatch({type:"SET_NICHMAT",value:res})

            if(!this.state.ready){
                this.setState({ready:true})
            }
        }catch
        {
            setTimeout(()=>this.getNichmat(),500)
        }
    }

    shuffle(array) {
        let currentIndex = array.length,  randomIndex;

        while (currentIndex != 0) {
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;

          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
    }


    segmentNichmat(nichmat)
    {
        var newNichmat={
            today:[],
            next:[],
            other:[]
        }

        nichmat.forEach((item)=>{
            if(item.deltaDays==0)
            {
                newNichmat.today.push(item)
            }else
            {
                if(item.deltaDays<15)
                {
                    //newNichmat.next.push(item)
                    newNichmat.other.push(item)

                }else
                {
                    newNichmat.other.push(item)
                }
            }
        })

        newNichmat.today=this.shuffle(newNichmat.today)
        newNichmat.next=this.shuffle(newNichmat.next)
        newNichmat.other=this.shuffle(newNichmat.other)

        return newNichmat
    }

    formatNichmat()
    {
        var data=[]

        this.props.nichmat.forEach(function (item){

            var anneeHE = new HDate(getDateNowFromZman(new Date())).getFullYear()

            item.hazkara=getHazkara(item,anneeHE)
            item.deltaDays=getDiffHDate(item)
            item.dateHE=getDateGematria(item)

            data.push(item)
        })

        return this.segmentNichmat(data)
        
    }

    show()
    {
        var elms=document.getElementsByClassName("ner-animation")
        for(var elm of elms)
        {
            elm.style.opacity=1
            elm.style.right="0"
        }
       
    }

    hide()
    {
        var elms=document.getElementsByClassName("ner-animation")
        for(var elm of elms)
        {
            elm.style.opacity=0
            elm.style.right="50px"
        }
    }

   
    render()
    {

        if(this.props.nichmat.length==0 && !this.state.ready)
        {
            return(
                <Activity/>
            )
        }

        if(this.props.nichmat.length==0)
        {
            return null
        }

        const nichmat=this.formatNichmat()

        return(
            <div className="ner-animation" style={{
                display:"flex",
                paddingTop:"1vh",
                paddingBottom:"1vh",
                paddingRight:"2vw",
                paddingLeft:"2vw",
                alignItems:"center",
                justifyContent:"center",
                width:"100vw",
            }}>

                {nichmat.today.length==0 && !this.props.config.PlaqueMiniScreen && <Mini data={nichmat} show={()=>{}} parent={this} />}
                
                {nichmat.today.length==0 && this.props.config.PlaqueMiniScreen && <Mini3x3 data={nichmat} show={()=>{}} parent={this} />}
                
                {nichmat.today.length!=0 &&<Big data={nichmat} show={()=>{}} parent={this} />}

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
      nichmat:state.nichmat,
      config:state.config,
    };
  };

export default connect(mapStateToProps)(Ner)