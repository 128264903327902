import React from "react";
import { BASE_URL, fontSize, REFRESH_PLAQUE_INTERVAL_TIME } from "../config";
import {connect} from "react-redux"
import { getDateGematria, getDateNowFromZman, getDiffHDate, getHazkara, getVariableZman, getZmanimDay } from "../hebcalFunctions";
import AutoScroll from "../components/AutoScroll";
import {Carousel} from "react-responsive-carousel";


class Zmanim extends React.Component{

    state={
        ready:false,
        needScroll:false,
        finishScroll:false,
        chiourim:[]
    }

    portrait=(localStorage.getItem("portrait") === "true")


    componentDidMount()
    {
        this.getData(true)
        
        if(!this.props.parent.isPreviz())
        {
            var maxtime=this.props.parent.getScreenTime(this.props.id)
            this.finishTimeout=setTimeout(()=>{
                this.finish()
            },maxtime)
        }
    }

    componentWillUnmount()
    {
        if(this.finishTimeout){
            clearTimeout(this.finishTimeout)
        }
    }

    finish()
    {
        if(this.state.needScroll && !this.state.finishScroll){
            setTimeout(()=>this.finish(),500)
            return false
        }

        console.log("FINISH")

        this.hide()

        setTimeout(()=>this.props.parent.next(),1500)
    }

    async getData(init=false)
    {
        console.log("GET CHIOURIM")

        try{
            const req=await fetch(BASE_URL+"/getChiourimv2.php?org="+localStorage.getItem("org"))
            const res=await req.json()

            this.setState({chiourim:res})

            if(init)
            {
                setTimeout(()=>this.show(),500)
                this.setState({ready:true})
            }
        }catch(e)
        {
            console.error(e)
            setTimeout(()=>this.getData(init),100)
        }
    }


    show()
    {
        var elms=document.getElementsByClassName("horaire-titre-animation")
        for(var elm of elms)
        {
            elm.style.opacity=1
            elm.style.right="0"
        }

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-bar-titre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=1
                elm.style.right="0"
            }
        },200)

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-cadre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=1
                elm.style.right="0"
            }
        },400)

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-text-animation")
            for(var elm of elms)
            {
                elm.style.opacity=1
            }
        },1000)
       
    }

    hide()
    {
        var elms=document.getElementsByClassName("horaire-text-animation")
        for(var elm of elms)
        {
            elm.style.opacity=0
        }
 
        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-cadre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=0
                elm.style.right="50px"
            }
        },200)

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-bar-titre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=0
                elm.style.right="100px"
            }
        },400)

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-titre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=0
                elm.style.right="50px"
            }
        },600)
    }

    getChiourim()
    {

        var data=this.state.chiourim

        data.sort(function(a,b){
            const ah=parseInt(a.horaire.split(":")[0])
            const am=parseInt(a.horaire.split(":")[1])
            const bh=parseInt(b.horaire.split(":")[0])
            const bm=parseInt(b.horaire.split(":")[1])

            var datea=new Date()
            datea.setHours(ah,am)

            var dateb=new Date()
            dateb.setHours(bh,bm)

            if(a.jour != b.jour){
                return (a.jour.localeCompare(b.jour));
            }
            else{
                return (datea - dateb);
            }
        })

        return data
    }

    formatJour(j)
    {
        const jours=["","Lundi","Mardi","Mercredi","Jeudi","Vendredi","Chabath","Dimanche"]

        return jours[j]
    }


    formatZman(date)
    {
        return ("0" + date.getHours()).slice(-2)+":"+("0" + date.getMinutes()).slice(-2)
    }
    

   
    render()
    {
        
        if(!this.state.ready)
        {
            return null
        }

        const chiourim=this.getChiourim()
        const zmanim=getZmanimDay(this.props.config,this.props.org)

        if(this.portrait)
        {
            return(
                <div style={{
                    display:"flex",
                    alignItems:"center",
                    flexDirection:"column",
                    width:"100vw",
                }}>
                    <div style={{marginTop: "5vh"}}>
                        <Carousel
                            showThumbs={false}
                            showArrows={false}
                            swipeable={false}
                            showStatus={false}
                            renderIndicator={this.renderIndicator}
                            autoPlay
                            interval={8000}
                            infiniteLoop
                            onChange={(index) => {
                                if (index === 0) {
                                    if (!this.props.parent.isPreviz()) {
                                        this.finish()
                                    }
                                }
                            }}
                            width={"100vw"}
                        >

                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                width:"100vw"
                            }}>
                                <span className="horaire-titre-animation" style={{
                                    fontFamily: "'Alice', serif",
                                    fontSize: fontSize(30)
                                }}>Horaires Halakhique</span>
                                <div className="horaire-cadre-animation" style={{
                                    display: "flex",
                                    height: "70vh",
                                    width: "100vw",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: "0px solid red",

                                }}>

                                    <div style={{
                                        display: "flex",
                                        height: "60vh",
                                        width: "100vw",
                                        justifyContent: "center",
                                        border: "0px solid green",
                                    }}>

                                        <AutoScroll
                                            onNeedScroll={() => this.setState({needScroll: true})}
                                            onFinishScroll={() => this.setState({finishScroll: true})}
                                            slow={40} delay={5000} style={{
                                            width: "59vh",
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "column"
                                        }}>


                                            {
                                                zmanim.map((item, index) => (
                                                    <span
                                                        className="horaire-text-animation"
                                                        key={index}
                                                        style={{
                                                            fontFamily: "'Alice', serif",
                                                            fontSize: fontSize(15),
                                                            color: item.next ? "red" : "auto",
                                                            marginBottom: "0.5vh"
                                                        }}>
                                        {item.nom}: {this.formatZman(item.time)}
                                        </span>
                                                ))
                                            }

                                        </AutoScroll>

                                    </div>

                                </div>
                            </div>

                            {chiourim.length != 0 &&
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    width:"100vw"
                                }}>
                                    <span className="horaire-titre-animation"
                                          style={{fontFamily: "'Alice', serif", fontSize: fontSize(30)}}>Horaires des Cours</span>
                                    <div className="horaire-cadre-animation" style={{
                                        display: "flex",
                                        height: "70vh",
                                        width: "100vw",
                                        justifyContent: "center",
                                        alignItems: "center",

                                        border: "0px solid red",

                                    }}>

                                        <div style={{
                                            display: "flex",
                                            height: "70vh",
                                            width: "100vw",
                                            justifyContent: "center",
                                            paddingTop: "3vh",
                                            paddingBottom: "3vh",

                                            border: "0px solid green",
                                        }}>

                                            <AutoScroll
                                                onNeedScroll={() => this.setState({needScroll: true})}
                                                onFinishScroll={() => this.setState({finishScroll: true})}
                                                slow={40} delay={5000} style={{
                                                width: "100vw",
                                                display: "flex",
                                                alignItems: "center",
                                                flexDirection: "column"
                                            }}>
                                                {
                                                    chiourim.map((item, index) => (
                                                        <div key={index} className="horaire-text-animation" style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            width: "100%",
                                                            marginBottom: "2vh",
                                                            paddingBottom: "1vh",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                width: "100%",
                                                                justifyContent: "space-around",
                                                                alignItems: "center",
                                                                marginBottom: "1vh",
                                                            }}>

                                                                <span style={{
                                                                    fontFamily: "Bodoni FLF",
                                                                    fontSize: fontSize(15)
                                                                }}>{this.formatJour(item.jour)}</span>

                                                                <span style={{
                                                                    fontFamily: "Bodoni FLF",
                                                                    fontSize: fontSize(15)
                                                                }}>{item.horaire}</span>

                                                            </div>

                                                            <span style={{
                                                                fontFamily: "'Alice', serif",
                                                                fontSize: fontSize(15),
                                                                fontStyle: "italic",
                                                                marginBottom: "1vh"
                                                            }}>{item.nom}</span>

                                                            <div className="horaire-bar-titre-animation" style={{
                                                                height: "0.3vh",
                                                                width: "40vw",
                                                                borderRadius: "25px",
                                                                backgroundColor: "#000"
                                                            }}></div>
                                                        </div>
                                                    ))
                                                }

                                            </AutoScroll>

                                        </div>

                                    </div>
                                </div>
                            }

                        </Carousel>

                    </div>

                </div>
            )
        }

        return(
            <div style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "100vw",
            }}>
                <div style={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "row",
                    width: "100vw",
                    marginTop: "0vh"
                }}>

                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column"
                    }}>
                        <span className="horaire-titre-animation"
                              style={{fontFamily: "'Alice', serif", fontSize: fontSize(20)}}>Horaires Halakhique</span>
                <div className="horaire-cadre-animation" style={{
                        backgroundImage:"url(/images/cadre-5.png)",
                        backgroundSize:"contain",
                        backgroundRepeat:"no-repeat",
                        display:"flex",
                        height:"70vh",
                        width:"69vh",
                        justifyContent:"center",
                        alignItems:"center",
                        border:"0px solid red",
                        
                    }}>

                        <div style={{
                            display:"flex",
                            height:"60vh",
                            width:"59vh",
                            justifyContent:"center",
                            border:"0px solid green",
                        }}>

                            <AutoScroll
                            onNeedScroll={()=>this.setState({needScroll:true})} 
                            onFinishScroll={()=>this.setState({finishScroll:true})}  
                            slow={40} delay={5000} style={{width:"59vh",display:"flex",alignItems:"center",flexDirection:"column"}}>
                                

                                {
                                    zmanim.map((item,index)=>(
                                        <span 
                                        className="horaire-text-animation"
                                        key={index}
                                        style={{
                                            fontFamily:"'Alice', serif",
                                            fontSize:fontSize(10.5),
                                            color:item.next?"red":"auto",
                                            marginBottom:"0.5vh"
                                        }}>
                                        {item.nom}: {this.formatZman(item.time)}
                                        </span>
                                    ))
                                }
                                    
                            </AutoScroll> 
                            
                        </div>
                        
                    </div>
                </div>

                {chiourim.length!=0 && 
                    <div style={{
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center",
                        flexDirection:"column"
                    }}>
                        <span className="horaire-titre-animation" style={{fontFamily:"'Alice', serif",fontSize:fontSize(20)}}>Horaires des Cours</span>
                        <div className="horaire-cadre-animation" style={{
                            backgroundImage:"url(/images/cadre-5.png)",
                            backgroundSize:"contain",
                            backgroundRepeat:"no-repeat",
                            display:"flex",
                            height:"70vh",
                            width:"69vh",
                            justifyContent:"center",
                            alignItems:"center",

                            border:"0px solid red",
                            
                        }}>

                            <div style={{
                                display:"flex",
                                height:"60vh",
                                width:"59vh",
                                justifyContent:"center",
                                paddingTop:"3vh",
                                paddingBottom:"3vh",

                                border:"0px solid green",
                            }}>

                                <AutoScroll
                                onNeedScroll={()=>this.setState({needScroll:true})} 
                                onFinishScroll={()=>this.setState({finishScroll:true})}  
                                slow={40} delay={5000} style={{width:"32vw",display:"flex",alignItems:"center",flexDirection:"column"}}>
                                        {
                                            chiourim.map((item,index)=>(
                                                <div key={index} className="horaire-text-animation" style={{
                                                    display:"flex",
                                                    flexDirection:"column",
                                                    width:"100%",
                                                    marginBottom:"2vh",
                                                    paddingBottom:"1vh",
                                                    justifyContent:"center",
                                                    alignItems:"center",
                                                }}>
                                                <div style={{
                                                    display:"flex",
                                                    flexDirection:"row",
                                                    width:"100%",
                                                    justifyContent:"space-around",
                                                    alignItems:"center",
                                                    marginBottom:"1vh",
                                                }}>

                                                <span style={{fontFamily:"Bodoni FLF",fontSize:fontSize(13)}} >{this.formatJour(item.jour)}</span>
                                                <span style={{fontFamily:"Bodoni FLF",fontSize:fontSize(13)}} >à</span>
                                                <span style={{fontFamily:"Bodoni FLF",fontSize:fontSize(13)}} >{item.horaire}</span>
                                                    
                                                </div>

                                                    <span style={{fontFamily:"'Alice', serif",fontSize:fontSize(13),fontStyle:"italic",marginBottom:"1vh"}} >{item.nom}</span>
                                                    
                                                    <div className="horaire-bar-titre-animation" style={{height:"0.5vh",width:"25vw",borderRadius:"25px",backgroundColor:"#000"}}></div>
                                                </div>
                                            ))
                                        }
                                        
                                </AutoScroll> 
                                
                            </div>
                            
                        </div>
                    </div>
                }
                
                </div>
        
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
      org:state.org,
      chiourim:state.chiourim,
      config:state.config
    };
  };

export default connect(mapStateToProps)(Zmanim)