import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache';
import store from './reducers/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';



ReactDOM.render(
  <Provider store={store}>
    <ClearCacheProvider duration={5000} auto >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ClearCacheProvider>
  </Provider>
  ,document.getElementById('root')
);

serviceWorkerRegistration.register()