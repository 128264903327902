import { configureStore } from "@reduxjs/toolkit";
import org from "./org"
import config from "./config"
import nichmat from "./nichmat"
import screens from "./screens"
import horaire from "./horaire"
import chiourim from "./chiourim"

//FETES
import roshHashana from "./roshhashana"
import yomKippour from "./yomkippour"
import souccot1 from "./souccot1"
import souccot2 from "./souccot2"
import pessah1 from "./pessah1"
import pessah2 from "./pessah2"


export default configureStore({
    reducer:{
        org,
        config,
        nichmat,
        screens,
        horaire,
        chiourim,
        roshHashana,
        yomKippour,
        souccot1,
        souccot2,
        pessah1,
        pessah2,
    }
});
