import React, { Fragment } from "react";
import { Animated } from "react-animated-css";
import { connect } from "react-redux";
import { fontSize } from "../config";
import { getHebrewDate, getNextEntreeShabbat, getNextParasha, getNextSortieShabbat } from "../hebcalFunctions";
import {BASE_URL} from "../config"

class Footer extends React.Component{

    portrait=(localStorage.getItem("portrait") === "true")


    render()
    {
        var text=""
        if(this.props.config)
        {
            text=this.props.config.FooterText.replaceAll("\n"," ").replace("\r"," ")
        }

        var textComponent=(<span style={{fontFamily:"'Open Sans', sans-serif",fontSize:fontSize(10),fontWeight:"lighter",marginTop:"1vh",width:"90vw",textAlign:"center"}}>{text}</span>)

        if(text.length>130)
        {
            console.log("SCROLLING TEXT")
            textComponent=(<ScrollingText text={text} />)
            //console.log(text)
        }

        if(this.props.org.id==7)
        {
            textComponent=(<AntibesFooterText text={text} />)
        }

        if(this.portrait)
        {
            return(
                <div style={{width:"100vw",display:"flex",alignItems:"center",flexDirection:"column",position:"relative"}}>
                    <div className="border" style={{height:"0.3vh",width:"80vw",borderRadius:"20px",backgroundColor:"#000"}}></div>

                    <img src={"/images/logo.png"}
                         style={{
                             opacity:1,
                             borderRadius:"10px",
                             height:"5vh",
                             width:"5vw",
                             position:"absolute",
                             bottom:0,
                             left:20,
                             objectFit:"contain"
                         }}
                    />


                    {textComponent}

                    {this.props.config.logo!="" &&
                        <img src={BASE_URL+"/images/"+this.props.config.logo}
                             style={{
                                 opacity:0.8,
                                 borderRadius:"10px",
                                 height:"5vh",
                                 width:"5vw",
                                 position:"absolute",
                                 bottom:0,
                                 right:15,
                                 objectFit:"contain"
                             }}
                        />
                    }
                </div>

            )
        }
        
        return(
            <div style={{width:"100vw",display:"flex",alignItems:"center",flexDirection:"column",position:"relative"}}>
                <div className="border" style={{height:"0.5vh",width:"90vw",borderRadius:"20px",backgroundColor:"#000"}}></div>

                <img src={"/images/logo.png"} 
                    style={{
                        opacity:1,
                        borderRadius:"10px",
                        height:"5vh",
                        width:"5vw",
                        position:"absolute",
                        bottom:0,
                        left:5,
                        objectFit:"contain"
                    }} 
                    />
                

                {textComponent}
                
                {this.props.config.logo!="" &&
                    <img src={BASE_URL+"/images/"+this.props.config.logo} 
                    style={{
                        opacity:0.8,
                        borderRadius:"10px",
                        height:"5vh",
                        width:"5vw",
                        position:"absolute",
                        bottom:0,
                        right:15,
                        objectFit:"contain"
                    }} 
                    />
                }
            </div>
            
        )
    }
}

class ScrollingText extends React.Component
{
    render()
    {
        return(
            <marquee direction="left" style={{marginTop:"1vh",width:"80vw"}}>
                <span  style={{fontFamily:"'Open Sans', sans-serif",fontSize:fontSize(10),fontWeight:"lighter"}}>
                    {this.props.text}
                </span> 
            </marquee>
        )

        return(
            <div style={{whiteSpace:"nowrap",overflow: 'hidden',}}>
                <div id="scrollingContainer" >
                    <span  style={{fontFamily:"'Open Sans', sans-serif",fontSize:fontSize(10),fontWeight:"lighter"}}>
                        {this.props.text}
                        {this.props.text}
                    </span> 
                </div>
            </div>
            
        )
    }
}


class AntibesFooterText extends React.Component
{
    portrait=(localStorage.getItem("portrait") === "true")

    render()
    {
        return(
            <div style={{marginTop:this.portrait?"0.5vh":"1vh",width:this.portrait?"80vw":"88vw",display:"flex",alignItems:"center",justifyContent:"space-between",flexDirection:"row"}}>
                <span style={{fontFamily:"'Open Sans', sans-serif",fontSize:fontSize(10),fontWeight:"bold"}}>Membres du Club Haï:</span>
                <marquee direction="left" style={{width:"70vw",border:"0px solid red"}}>
                    <span  style={{fontFamily:"'Open Sans', sans-serif",fontSize:fontSize(10),fontWeight:"bold"}}>
                        {this.props.text}
                    </span> 
                </marquee>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
      org:state.org,
      config:state.config,
    };
  };
  
  export default connect(mapStateToProps)(Footer)