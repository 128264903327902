import React, { Fragment } from "react";
import RequestOrg from "./RequestOrg";
import ErrorScreen from "./Error";

import {BASE_URL,cacheImage,GETDATA_INTERVAL_TIME} from "./config"
import Version from "./Version";
import Header from "./components/Header";
import { connect } from "react-redux";
import { generateHebCal, isChabbat, isOmer, isYomTov, LAT, LONG, needMolad, setLocation } from "./hebcalFunctions";
import Footer from "./components/Footer";
import Ner from "./screens/Ner";
import { Routes,Route,useNavigate } from "react-router-dom";
import Horaire from "./screens/Horaire";
import HayomYom from "./screens/HayomYom";
import SeferHamistvot from "./screens/SeferHamitsvot";
import Omer from "./screens/Omer";
import Zmanim from "./screens/Zmanim";
import CustomImage from "./screens/CustomImage";
import Activity from "./components/Activity";
import CustomText from "./screens/CustomText";
import HalahaYomit from "./screens/HalahaYomit";
import {getZmanimHabad} from "./hebcalFunctions"
import Geoula from "./screens/Geoula";
import Molad from "./screens/Molad";
import CustomVideo from "./screens/CustomVideo";
import RoshHashana from "./screens/RoshHashana";
import YomKippour from "./screens/YomKippour";
import Souccot1 from "./screens/Souccot1";
import Souccot2 from "./screens/Souccot2";
import Chiourim from "./screens/Chiourim";
import EmbedImage from "./screens/EmbedImage";
import Pessah1 from "./screens/Pessah1";
import Pessah2 from "./screens/Pessah2";
import Chavouot from "./screens/Chavouot";

function randomIntFromInterval(min, max) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min);
}

class App extends React.Component{

  constructor(props)
  {
    super(props)

    if(window.location.pathname!="/"){
      window.location.href="/"
    }
  }

  state={
    ready:false,
    requestOrg:false,
    error:null,
    fullscreen:null
  }

  counterRefresh=10

  screensHistory=[]

  isInfinite()
  {
    if(this.props.screens.length<2){
      console.log("INFINITE")
      return true
    }
    return false
  }

  componentDidMount()
  {
    const urlParams = new URLSearchParams(window.location.search);

    var org=null
    var portrait=null

    if(urlParams.get("org"))
    {
      localStorage.setItem("org",urlParams.get("org"))
    }

    if(urlParams.get("portrait"))
    {
      localStorage.setItem("portrait",urlParams.get("portrait"))
    }

    org=localStorage.getItem("org")
    console.log("PORTRAIT",localStorage.getItem("org"))

    console.log("ORG",org)

    if(!org)
    {
      this.setState({requestOrg:true})
    }else
    {
      this.getData()
      this.ping()
    }

    this.intervalData=setInterval(()=>this.getData(),10*60*1000)
    this.intervalPing=setInterval(()=>this.ping(),2*60*1000) // 2 minutes

  }

  componentWillUnmount()
  {
    clearInterval(this.intervalData)
    clearInterval(this.intervalPing)
  }


  
  componentDidCatch(error, errorInfo) {
    console.log("CATCH ERROR",error,errorInfo)
    this.next(false)
  }


  async ping()
  {
    var org=localStorage.getItem("org")
    console.log("PING")
    fetch(BASE_URL+"/setPing.php?org="+org)
  }

  async getData()
  {
    var org=localStorage.getItem("org")

    console.log("GET DATA")
    try
    {
      var forceReload=false

      const req=await fetch(BASE_URL+"/getOrg.php?org="+org)
      const res=await req.json()

      await setLocation(res.latitude,res.longitude)
    

      this.props.dispatch({type:"SET_ORGINFO",value:res})

      const req2=await fetch(BASE_URL+"/getConfig.php?org="+org)
      const res2=await req2.json()

      await generateHebCal(res,res2)

      if(JSON.stringify(res2)!=JSON.stringify(this.props.config)){
        this.props.dispatch({type:"SET_CONFIG",value:res2})
        forceReload=true
      }
      
      const req3=await fetch(BASE_URL+"/getScreensv3.php?org="+org+"&force="+(this.isPreviz()?1:0)+"&portrait="+localStorage.getItem("portrait") )
      const res3=await req3.json()
      const screens=this.getAvailableScreens(res3)

      if(JSON.stringify(screens)!=JSON.stringify(this.props.screens)){
        this.props.dispatch({type:"SET_SCREENS",value:screens})
        forceReload=true
      }
      
      console.log("DATA OK")

      if(!this.state.ready){
        this.setState({ready:true})
      }

      if(forceReload && !this.isPreviz() && this.props.screens.length>0)
      {
        this.reload()
      }

      this.setStyleConfig()

    }catch(e)
    {
      console.log(e)
      setTimeout(()=>this.getData(),randomIntFromInterval(30, 60) *1000)
    }
    
  }

  setStyleConfig()
  {
    console.log("setStyleConfig")
    var body=document.getElementsByTagName("body")[0]
    if(this.props.config.overrideStyle)
    {
      body.insertAdjacentHTML("beforeend","<style>"+this.props.config.overrideStyle+"</style>")
    }else
    {
      body.style.backgroundImage='url("/images/'+this.props.config.bgimage+'")'
    }
    
  }

  getAvailableScreens(screens)
  {
    
    var availableScreens=[]

    for(var s of screens)
    {

      if(s.id=="OMER" && !isOmer()){
        continue
      }

      if(s.id=="MOLAD" && !needMolad() && !this.isPreviz()){
        continue
      }

      if(isYomTov())
      {
        s.time=s.yomtov
      }else if(isChabbat())
      {
        s.time=s.chabbat
      }else
      {
        s.time=s.semaine
      }

      if(s.time>0 || this.isPreviz()){
        availableScreens.push(s)
      }
    }

    availableScreens.forEach(s=>{
      //console.log(s)
      if(s.name=="CUSTOM" && s.data.type=="IMAGE"){
        cacheImage(s.data.image)
      }
    })

    //return [...availableScreens.slice(-2)]

    return availableScreens
  }

  reload()
  {
    console.log("RELOAD")
    this.props.navigate("/")
    this.setState({fullscreen:null})
    this.screensHistory=[]
    this.navigateTo(this.props.screens[0])
  }

  next(withDelay=true)
  { 
    
      setTimeout(()=>{

        try
        {
          
  
          if(this.screensHistory.length>=this.props.screens.length){
            //FORCE REFRESH PAGE
            if(localStorage.getItem("org")!=7)
            {
              window.location.reload()
            }else
            {
              this.counterRefresh--
              if(this.counterRefresh==0){
                window.location.reload()
                return false
              }
              
              this.reload()
            }
  
          }else
          {
            this.navigateTo(this.props.screens[this.screensHistory.length])
          }
          
        }catch(e)
        {
          console.error(e)
          this.reload()
        }
  
      },withDelay?500:0)
  }

  navigateTo(screen)
  {
    const config=this.props.config
    this.screensHistory.push(screen)
    var route=null
    switch(screen.name)
    {
      case "NER":
        route="/ner"
        break;
      case "HORAIRE":
        route="/horaire"
        break;
      case "CHIOURIM":
        route="/chiourim"
        break;
      case "ZMANIM":
        route="/zmanim"
        break;
      case "HAYOMYOM":
        route="/hayomyom"
        break;
      case "GEOULA":
        route="/geoula"
        break;
      case "MOLAD":
        route="/molad"
        break;
      case "HALAHAYOMIT":
        route="/halahayomit"
        break;
      case "SEFERMITSVOT":
        route="/sefermitsvot"
        break;
      case "OMER":
        route="/omer"
        break;
      
      case "ROSHHASHANA":
        route="/roshhashana"
      break;
      case "YOMKIPPOUR":
        route="/yomkippour"
      break;
      case "SOUCCOT1":
        route="/souccot1"
      break;
      case "SOUCCOT2":
        route="/souccot2"
      break;
      case "PESSAH1":
        route="/pessah1"
      break;
      case "PESSAH2":
        route="/pessah2"
      break;
      case "CHAVOUOT":
        route="/chavouot"
      break;
    }

    if(screen.name=="CUSTOM")
    {
      console.log("CUSTOM SCREEN",screen.id)
      
      const type=screen.data.type

      if(type=="IMAGE")
      { 
          if(config.EmbedImage=="1")
          {
            this.props.navigate("/")
            console.log("EMBED IMAGE")
            route="/embedimage"
          }else
          {
            console.log("FULLSCREEN")
            this.setState({
              fullscreen:<CustomImage id={screen.id} parent={this}/>
            })
            //route="/customimage"
          }
          
      }

      if(type=="VIDEO")
      {   
          console.log("FULLSCREEN")
          this.setState({
            fullscreen:<CustomVideo id={screen.id} parent={this}/>
          })

          //route="/customvideo"
      }

      if(type=="TEXT")
      {
        this.props.navigate("/")
        route="/customtext"
      }

    }

    if(route)
    {
      this.setState({fullscreen:null},()=>{
        this.props.navigate(route)
      })
      
    }
  }

  getScreen(id)
  {
    for(var item of this.props.screens)
    {
      if(item.id==id){
        return item
      }
    }

    this.reload()
    return null
  }

  getCurrentScreen()
  {
    var screen=this.screensHistory[this.screensHistory.length-1]
    if(screen){
      return screen
    }
    console.log("NO SCREEN getCurrentScreen()")
    this.reload()
    return false
  }

  getScreenTime(id)
  {
    const time=this.getScreen(id).time
    console.log("SCREEN TIME",time)
    return time*1000
  }

  isPreviz()
  {
    const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.get('previz')){
      console.log("PREVIZ")
      return true
    }
    return false
  }

  getPrevizComponent()
  {
    const config=this.props.config
    const urlParams = new URLSearchParams(window.location.search);
    const previz=urlParams.get('previz')
    switch(previz)
    {
      case "NER":
        return <Ner id={previz} parent={this} />
      case "HORAIRE":
        return <Horaire id={previz} parent={this} />
      case "CHIOURIM":
        return <Chiourim id={previz} parent={this} />
      case "ZMANIM":
        return <Zmanim id={previz} parent={this} />
      case "HAYOMYOM":
        return <HayomYom id={previz} parent={this} />
      case "GEOULA":
        return <Geoula id={previz} parent={this} />
      case "MOLAD":
        return <Molad id={previz} parent={this} />
      case "HALAHAYOMIT":
        return <HalahaYomit id={previz} parent={this} />
      case "SEFERMITSVOT":
        return <SeferHamistvot id={previz} parent={this} />
      case "OMER":
        return <Omer id={previz} parent={this} />

      case "ROSHHASHANA":
        return <RoshHashana id={previz} parent={this} />
      case "YOMKIPPOUR":
        return <YomKippour id={previz} parent={this} />
      case "SOUCCOT1":
        return <Souccot1 id={previz} parent={this} />
      case "SOUCCOT2":
        return <Souccot2 id={previz} parent={this} />
      case "PESSAH1":
        return <Pessah1 id={previz} parent={this} />
      case "PESSAH2":
        return <Pessah2 id={previz} parent={this} />
      case "CHAVOUOT":
        return <Chavouot id={previz} parent={this} />
    }
    
    if(!isNaN(parseInt(previz)))
    {
      //console.log("EXTRA PREVIZ")

      const type=urlParams.get("type")

      if(type=="IMAGE")
      {   
        console.log(config)
        if(config.EmbedImage=="1")
          {
            console.log("EMBED IMAGE")
            return <EmbedImage id={previz} parent={this} />

          }else
          {
            console.log("FULLSCREEN",previz)
            this.setState({
              fullscreen:<CustomImage id={previz} parent={this}/>
            })
  
            return null
          }
          
      }

      if(type=="VIDEO")
      {   
          console.log("FULLSCREEN",previz)
          this.setState({
            fullscreen:<CustomVideo id={previz} parent={this}/>
          })

        return null
      }

      if(type=="TEXT")
      {
        console.log("TEXT",previz)
        //return null
        return <CustomText id={previz} parent={this} />
      }

    }
  }

  getFilter()
  {
    const config=this.props.config

    var src=null

    if(config.FiltreActive==0){
      return null
    }

    if(config.Pessah1Active==1 || config.Pessah2Active==1){
      src="/images/filtre/pessah.jpg"
    }

    if(config.Souccot2Active==1){
      src="/images/filtre/simhatorah.png"
    }

    if(config.Souccot1Active==1){
      src="/images/filtre/souccot.jpg"
    }

    if(config.YomKippourActive==1){
      src="/images/filtre/kippour.jpeg"
    }

    if(config.RoshHashanaActive==1){
      src="/images/filtre/roshhashana.jpg"
    }

    if(src){
      return <img style={{position:"absolute",top:0,bottom:0,right:0,left:0,zIndex:0,objectFit:"cover",opacity:0.2,height:"100vh",width:"100vw"}} src={src}/>
    }

    return null
  }

  render()
  {
    console.log("HISTORY",this.screensHistory)

    const config=this.props.config

    if(this.state.requestOrg)
    {
      return <RequestOrg onValidate={()=>{
        this.setState({requestOrg:false})
        this.getData()
      }} />
    }

    if(!this.state.ready)
    {
      return (
        <div style={{height:"100vh",width:"100vw",backgroundColor:"#000"}}>
          <Activity/>
        </div> 
      )
    }

    if(this.state.fullscreen)
    {
      return this.state.fullscreen
    }

    if(config.OnlyFullScreen && !this.isPreviz())
    {
      return (
        <div style={{height:"100vh",width:"100vw",backgroundColor:"#000"}}></div> 
      )
    }


    const portrait=(localStorage.getItem("portrait") === "true")


    return (
      <div style={{position:"relative"}}>
      
        {this.getFilter()}


        <div style={{position:"absolute",top:0,bottom:0,right:0,left:0,zIndex:10000}}>
          <Header/>
          <div style={{display:"flex",height:portrait?"85vh":"80vh",width:"100vw",border:"0px solid black"}}>

          {this.isPreviz()&& this.getPrevizComponent()}

          {(!this.isPreviz() && this.props.screens.length>0) &&
            <Routes>
              <Route exact path="/" element={null} />
              <Route exact path="/ner" element={<Ner id="NER" parent={this} />} />
              <Route exact path="/horaire" element={<Horaire id="HORAIRE" parent={this} />} />
              <Route exact path="/chiourim" element={<Chiourim id="CHIOURIM" parent={this} />} />
              <Route exact path="/zmanim" element={<Zmanim id="ZMANIM" parent={this} />} />
              <Route exact path="/hayomyom" element={<HayomYom id="HAYOMYOM" parent={this} />} />
              <Route exact path="/geoula" element={<Geoula id="GEOULA" parent={this} />} />
              <Route exact path="/molad" element={<Molad id="MOLAD" parent={this} />} />
              <Route exact path="/halahayomit" element={<HalahaYomit id="HALAHAYOMIT" parent={this} />} />
              <Route exact path="/sefermitsvot" element={<SeferHamistvot id="SEFERMITSVOT" parent={this} />} />
              <Route exact path="/omer" element={<Omer id="OMER" parent={this} />} />
              <Route exact path="/customtext" element={<CustomText parent={this} />} />
              <Route exact path="/embedimage" element={<EmbedImage parent={this} />} />

              <Route exact path="/roshhashana" element={<RoshHashana id="ROSHHASHANA" parent={this} />} />
              <Route exact path="/yomkippour" element={<YomKippour id="YOMKIPPOUR" parent={this} />} />
              <Route exact path="/souccot1" element={<Souccot1 id="SOUCCOT1" parent={this} />} />
              <Route exact path="/souccot2" element={<Souccot2 id="SOUCCOT2" parent={this} />} />
              <Route exact path="/pessah1" element={<Pessah1 id="PESSAH1" parent={this} />} />
              <Route exact path="/pessah2" element={<Pessah2 id="PESSAH2" parent={this} />} />
              <Route exact path="/chavouot" element={<Chavouot id="CHAVOUOT" parent={this} />} />
            </Routes>
          }

          </div>
          <Footer/>
        </div>
      </div>
    )
    
  }
}

const mapStateToProps = state => {
  console.log("REDUX",state)
  return {
    org:state.org,
    screens:state.screens,
    config:state.config,
  };
};

function withNavigation(Component) {
  return function WrappedComponent(props) {
    const myHookValue = useNavigate();
    return <Component {...props} navigate={myHookValue} />;
  }
}

export default connect(mapStateToProps)(withNavigation(App))