import React from "react";
import { BASE_URL, fontSize, REFRESH_PLAQUE_INTERVAL_TIME } from "../config";
import {connect} from "react-redux"
import { getDateGematria, getDateNowFromZman, getDiffHDate, getHazkara, getHebCalByDate, getVariableZman } from "../hebcalFunctions";
import omerHabbad from "../data/omer-habbad.json"
import omerSefard from "../data/omer-sefard.json"

class Omer extends React.Component{

    componentDidMount()
    {
        setTimeout(()=>this.show(),500)

        if(!this.props.parent.isPreviz())
        {
            var maxtime=this.props.parent.getScreenTime(this.props.id)
            this.finishTimeout=setTimeout(()=>{
                this.finish()
            },maxtime)
        }
    }

    componentWillUnmount()
    {
        if(this.finishTimeout){
            clearTimeout(this.finishTimeout)
        }
    }

    finish()
    {
        console.log("FINISH")

        this.hide()

        setTimeout(()=>this.props.parent.next(),1500)
    }

    show()
    {
        var elms=document.getElementsByClassName("horaire-titre-animation")
        for(var elm of elms)
        {
            elm.style.opacity=1
            elm.style.right="0"
        }

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-bar-titre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=1
                elm.style.right="0"
            }
        },200)


        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-text-animation")
            for(var elm of elms)
            {
                elm.style.opacity=1
            }
        },1000)
       
    }

    hide()
    {
        var elms=document.getElementsByClassName("horaire-text-animation")
        for(var elm of elms)
        {
            elm.style.opacity=0
        }

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-bar-titre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=0
                elm.style.right="100px"
            }
        },400)

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-titre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=0
                elm.style.right="50px"
            }
        },600)
    }

    getData()
    {
        var hebCal=getHebCalByDate(getDateNowFromZman(new Date()))

        if(hebCal?.omer==null){
            //TODO erreur => next screens
            return null
        }
        
       

        if(this.props.org.nousah=="HABBAD")
        {
            return omerHabbad[hebCal.omer-1]
        }

        return omerSefard[hebCal.omer-1]
    }
    

   
    render()
    {
        var data=null

        try{
            data=this.getData()
        }catch(e){
            this.props.parent.next()
            return null
        }
        

        console.log(data)

        const footerSefarad="הָרַחֲמָן הוּא יַחֲזִיר עֲבוֹדַת בֵּית הַמִּקְדָּשׁ לִמְקוֹמָהּ בִּמְהֵרָה בְיָמֵינוּ אָמֵן"
        const footerHabbad="הָרַחֲמָן הוּא יַחֲזִיר לָנוּ עֲבוֹדַת בֵּית הַמִּקְדָּשׁ לִמְקוֹמָהּ בִּמְהֵרָה בְּיָמֵינוּ אָמֵן סֶלָה"

    
        return(
            <div style={{
                display:"flex",
                alignItems:"center",
                flexDirection:"column",
                width:"100vw",
            }}>

                <div style={{width:"100vw",display:"flex",alignItems:"center",flexDirection:"column"}}>
                    <span className="horaire-titre-animation" style={{fontFamily:"'Alice', serif",fontSize:fontSize(25)}}>Sefirath Haomer</span>
                    <div className="horaire-bar-titre-animation" style={{height:"0.5vh",width:"35vw",borderRadius:"25px",backgroundColor:"#000",marginBottom:"1vh"}}></div>
                </div>

                <span className="horaire-titre-animation" 
                style={{
                    display:"flex",
                    alignSelf:"center",
                    textAlign:"center",
                    fontFamily:"Frank Ruhl Libre",
                    fontWeight:"500",
                    fontSize:fontSize(40),
                }}>
                    {data.omer}
                </span>

                <div className="horaire-text-animation" style={{
                    display:"flex",
                    alignSelf:"center",
                    textAlign:"center",
                    width:"90vw",
                    fontFamily:"Frank Ruhl Libre",
                    fontSize:fontSize(25),
                    color:"#CCAC3C",
                    fontWeight:"bold",
                    flexDirection:"row",
                    justifyContent:"space-around",
                    marginTop:"3vh"
                }}>
                <span>{data.attr[3]}</span>
                <span>{data.attr[2]}</span>
                <span>{data.attr[1]}</span>
                <span>{data.attr[0]}</span>
                </div>
        

                <span className="horaire-text-animation" 
                style={{
                    display:"flex",
                    alignSelf:"center",
                    textAlign:"center",
                    width:"80vw",
                    fontFamily:"Frank Ruhl Libre",
                    fontWeight:"400",
                    fontSize:fontSize(25),
                }}>
                    {this.props.org.nousah=="HABBAD"?footerHabbad:footerSefarad}
                </span>
        
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
      org:state.org,
    };
  };

export default connect(mapStateToProps)(Omer)