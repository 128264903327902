import React from "react";
import { VERSION_NAME } from "./config";

export default class Version extends React.Component{

    render()
    {
        return(
            <div style={{
                display:"flex",
                position:"absolute",
                bottom:0,
                right:0,
                marginRight:"20px",
                marginBottom:"10px",
                backgroundColor:"rgba(255,255,255,0.5)",
                padding:"2px 5px",
                borderRadius:"5px",
                justifyContent:"center",
                alignItems:"center"
            }}>

                <span>{VERSION_NAME}</span>

            </div>
        )
    }
}