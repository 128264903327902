import React from "react";
import { BASE_URL, fontSize, REFRESH_PLAQUE_INTERVAL_TIME } from "../config";
import {connect} from "react-redux"
import { getDateGematria, getDateNowFromZman, getDiffHDate, getHazkara, getVariableZman } from "../hebcalFunctions";
import AutoScroll from "../components/AutoScroll";
import {seferHamitsvotResume} from "../data/seferHamitsvot"
import { Carousel } from 'react-responsive-carousel';

export default class SeferHamistvot extends React.Component{

    state={
        needScroll:false,
        finishScroll:false
    }

    portrait=(localStorage.getItem("portrait") === "true")


    componentDidMount()
    {
        setTimeout(()=>this.show(),500)
        if(!this.props.parent.isPreviz())
        {
            var maxtime=this.props.parent.getScreenTime(this.props.id)
            this.finishTimeout=setTimeout(()=>{
                this.finish()
            },maxtime)
        }
    }

    componentWillUnmount()
    {
        if(this.finishTimeout){
            clearTimeout(this.finishTimeout)
        }
    }

    finish()
    {
        if(this.state.needScroll && !this.state.finishScroll){
            setTimeout(()=>this.finish(),500)
            return false
        }

        console.log("FINISH")

        this.hide()

        setTimeout(()=>this.props.parent.next(),1500)
    }


    show()
    {
        var elms=document.getElementsByClassName("horaire-titre-animation")
        for(var elm of elms)
        {
            elm.style.opacity=1
            elm.style.right="0"
        }

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-bar-titre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=1
                elm.style.right="0"
            }
        },200)


        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-text-animation")
            for(var elm of elms)
            {
                elm.style.opacity=1
            }
        },1000)
       
    }

    hide()
    {
        var elms=document.getElementsByClassName("horaire-text-animation")
        for(var elm of elms)
        {
            elm.style.opacity=0
        }

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-bar-titre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=0
                elm.style.right="100px"
            }
        },400)

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-titre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=0
                elm.style.right="50px"
            }
        },600)
    }

   
    datediff(first, second) {
        return parseInt((second-first)/(1000*60*60*24));
    }

    getData()
    {
        var dateref=new Date("2021-06-14T00:00:00")
        var nbJour=this.datediff(dateref,new Date())+1
        //console.log(nbJour)

        while(nbJour>339)
        {
            nbJour=nbJour-339
        }

        var data=[]

        var keys=Object.keys( seferHamitsvotResume["cours"+nbJour])
        var values=Object.values( seferHamitsvotResume["cours"+nbJour])
        
        for(var i=0;i<keys.length;i++)
        {
            data.push({
                titre:keys[i],
                text:values[i].replaceAll("<br>",""),
            })
        }

        return data
    }
    

    renderIndicator=(clickHandler,isSelected,index,label)=>
    {
        return(
            <div 
            style={{
                display:"inline-block",
                borderRadius:"100%",
                height:"2vh",
                width:"2vh",
                marginLeft:"2vh",
                marginRight:"2vh",
                backgroundColor:isSelected?"black":"grey"
            }}></div>
        )
    }

   
    render()
    {
        const data=this.getData()

        console.log(data)

        if(data.length>1 && !this.state.needScroll){
            this.setState({needScroll:true})
        }

        if(this.portrait)
        {
            return(
                <div style={{
                    display:"flex",
                    alignItems:"center",
                    flexDirection:"column",
                    width:"100vw",
                    marginTop:"1vh"
                }}>

                    <div style={{width:"100vw",display:"flex",alignItems:"center",flexDirection:"column"}}>
                        <span className="horaire-titre-animation" style={{fontFamily:"'Alice', serif",fontSize:fontSize(30)}}>Sefer Hamitsvoth</span>
                        <div className="horaire-bar-titre-animation" style={{height:"0.3vh",width:"60vw",borderRadius:"25px",backgroundColor:"#000",marginBottom:"2vh"}}></div>
                    </div>

                    {data.length>1 &&
                        <div style={{width:"100vw"}}>
                            <Carousel
                                showThumbs={false}
                                showArrows={false}
                                swipeable={false}
                                showStatus={false}
                                autoPlay
                                infiniteLoop
                                interval={8000}
                                renderIndicator={this.renderIndicator}
                                onChange={(index)=>{
                                    if(index==0){
                                        this.setState({finishScroll:true})
                                    }
                                }}
                                style={{}}
                            >
                                {
                                    data.map((item,index)=>(
                                        <div key={index} style={{width:"100vw",display:"flex",justifyContent:"center",alignItems:"center",border:"0px solid black"}}>
                                            <div style={{height:"65vh",width:"90vw",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",border:"0px solid red"}}>
                                                <h1 className="horaire-titre-animation" style={{fontFamily:"'Times New Roman', Times, serif",fontSize:fontSize(20),whiteSpace:"pre-line",fontWeight:"bold",textAlign:"center"}}>{item.titre}:</h1>
                                                <span className="horaire-text-animation" style={{fontFamily:"'Times New Roman', Times, serif",fontSize:fontSize(20),whiteSpace:"pre-line",textAlign:"center"}}>{item.text}</span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </Carousel>
                        </div>
                    }


                    {data.length==1 &&(
                        <div style={{height:"65vh",width:"90vw",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",border:"0px solid red"}}>
                            <h1 className="horaire-titre-animation" style={{fontFamily:"'Times New Roman', Times, serif",fontSize:fontSize(20),whiteSpace:"pre-line",fontWeight:"bold",textAlign:"center"}}>{data[0].titre}:</h1>
                            <span className="horaire-text-animation" style={{fontFamily:"'Times New Roman', Times, serif",fontSize:fontSize(20),whiteSpace:"pre-line",textAlign:"center"}}>{data[0].text}</span>
                        </div>
                    )}

                </div>
            )
        }

    
        return(
            <div style={{
                display:"flex",
                alignItems:"center",
                flexDirection:"column",
                width:"100vw",
            }}>

                <div style={{width:"100vw",display:"flex",alignItems:"center",flexDirection:"column"}}>
                    <span className="horaire-titre-animation" style={{fontFamily:"'Alice', serif",fontSize:fontSize(25)}}>Sefer Hamitsvoth</span>
                    <div className="horaire-bar-titre-animation" style={{height:"0.5vh",width:"35vw",borderRadius:"25px",backgroundColor:"#000",marginBottom:"2vh"}}></div>
                </div>

                {data.length>1 && 
                <div style={{width:"100vw"}}>    
                    <Carousel 
                    showThumbs={false}
                    showArrows={false}
                    swipeable={false}
                    showStatus={false}
                    autoPlay
                    infiniteLoop
                    interval={8000}
                    renderIndicator={this.renderIndicator}
                    onChange={(index)=>{
                        if(index==0){
                            this.setState({finishScroll:true})
                        }
                    }}
                    style={{}}
                    >
                        {
                            data.map((item,index)=>(
                                <div key={index} style={{width:"100vw",display:"flex",justifyContent:"center",alignItems:"center",border:"0px solid black"}}>
                                    <div style={{height:"65vh",width:"80vw",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",border:"0px solid red"}}>
                                        <h1 className="horaire-titre-animation" style={{fontFamily:"'Times New Roman', Times, serif",fontSize:fontSize(20),whiteSpace:"pre-line",fontWeight:"bold",textAlign:"center"}}>{item.titre}:</h1>
                                        <span className="horaire-text-animation" style={{fontFamily:"'Times New Roman', Times, serif",fontSize:fontSize(20),whiteSpace:"pre-line",textAlign:"center"}}>{item.text}</span>
                                    </div>
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
                }


                {data.length==1 && 
                <div style={{height:"65vh",width:"80vw",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",border:"0px solid red"}}>
                    <h1 className="horaire-titre-animation" style={{fontFamily:"'Times New Roman', Times, serif",fontSize:fontSize(20),whiteSpace:"pre-line",fontWeight:"bold",textAlign:"center"}}>{data[0].titre}:</h1>
                    <span className="horaire-text-animation" style={{fontFamily:"'Times New Roman', Times, serif",fontSize:fontSize(20),whiteSpace:"pre-line",textAlign:"center"}}>{data[0].text}</span>
                </div>}
        
            </div>
        )
    }
}