import React from "react";
import { BASE_URL, fontSize, REFRESH_PLAQUE_INTERVAL_TIME } from "../config";
import {connect} from "react-redux"
import { getDateGematria, getDateNowFromZman, getDiffHDate, getHazkara, getVariableZman } from "../hebcalFunctions";
import AutoScroll from "../components/AutoScroll";
import hayomYom from "../data/hayomYom";
import { HDate } from "@hebcal/core";

export default class HalahaYomit extends React.Component{

    state={
        needScroll:false,
        finishScroll:false
    }

    componentDidMount()
    {
        setTimeout(()=>this.show(),500)
        
        if(!this.props.parent.isPreviz())
        {
            var maxtime=this.props.parent.getScreenTime(this.props.id)
            setTimeout(()=>{
                this.finish()
            },maxtime)
        }
    }

    finish()
    {
        if(this.state.needScroll && !this.state.finishScroll){
            setTimeout(()=>this.finish(),500)
            return false
        }

        console.log("FINISH")

        this.hide()

        setTimeout(()=>this.props.parent.next(),1500)
    }


    show()
    {
        var elms=document.getElementsByClassName("horaire-titre-animation")
        for(var elm of elms)
        {
            elm.style.opacity=1
            elm.style.right="0"
        }

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-bar-titre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=1
                elm.style.right="0"
            }
        },200)


        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-text-animation")
            for(var elm of elms)
            {
                elm.style.opacity=1
            }
        },1000)
       
    }

    hide()
    {
        var elms=document.getElementsByClassName("horaire-text-animation")
        for(var elm of elms)
        {
            elm.style.opacity=0
        }

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-bar-titre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=0
                elm.style.right="100px"
            }
        },400)

        setTimeout(()=>{
            var elms=document.getElementsByClassName("horaire-titre-animation")
            for(var elm of elms)
            {
                elm.style.opacity=0
                elm.style.right="50px"
            }
        },600)
    }

   

    getData()
    {
        var hdate=new HDate(new Date())
        return hayomYom[hdate.getDate()+"-"+hdate.getMonth()]["fr"].replace(/(<br>)+/g, '\n')
    }
    

   
    render()
    {
        
        const text=this.getData()

        return(
            <div style={{
                display:"flex",
                alignItems:"center",
                flexDirection:"column",
                width:"100vw",
            }}>

                <div style={{width:"100vw",display:"flex",alignItems:"center",flexDirection:"column"}}>
                    <span className="horaire-titre-animation" style={{fontFamily:"'Alice', serif",fontSize:fontSize(25)}}>Halaha Yomit</span>
                    <div className="horaire-bar-titre-animation" style={{height:"0.5vh",width:"25vw",borderRadius:"25px",backgroundColor:"#000",marginBottom:"3vh"}}></div>
                </div>

                <div style={{width:"100vw",display:"flex",alignItems:"flex-start",flexDirection:"column",marginLeft:"10vw",textDecorationLine:"spelling-error"}}>
                    <span className="horaire-titre-animation" style={{fontFamily:"'Alice', serif",fontSize:fontSize(20),fontWeight:"600"}}>Cuire et cuisiner pendant Yom Tov :</span>                </div>

                <AutoScroll
                onNeedScroll={()=>this.setState({needScroll:true})} 
                onFinishScroll={()=>this.setState({finishScroll:true})} 
                 delay={15000} slow={40} style={{height:"55vh",width:"90vw",border:"0px solid red",marginTop:"3vh"}}>
                    <span className="horaire-text-animation" style={{fontFamily:"Alice, serif",fontSize:fontSize(17),whiteSpace:"pre-line"}}>
                    Tout aliment dont le goût est meilleur lorsqu’il est consommé immédiatement après sa préparation, comme un légume frit par exemple, il est permis de cuisiner un tel aliment le jour de Yom Tov. Mais un aliment qui ne perd aucune saveur même lorsqu’il est cuisiné la veille avant la fête, Léh’atéh’ila (à priori) il ne faut pas retarder sa préparation au jour de Yom Tov lui-même, il faudra le cuisiner depuis la veille de Yom Tov, comme nous l’avons expliqué.
                    </span>
                </AutoScroll>
        
            </div>
        )
    }
}