import React from "react";
import { BASE_MEDIA_URL, fontSize, getCachedImage, REFRESH_PLAQUE_INTERVAL_TIME } from "../config";


export default class EmbedImage extends React.Component{
    
    componentDidMount()
    {
        console.log("componentDidMount EmbedImage")
        this.init()
    }

    componentDidUpdate(prevProps)
    {
        console.log(this.props.id)  

        if(prevProps.id!==this.props.id)
        {
            console.log("FORCE RELAD UPDATE")
            this.init()
        }
    }

    init()
    {
        setTimeout(()=>this.show(),500)

        if(!this.props.parent.isPreviz())
        {
            const screen=this.props.parent.getCurrentScreen()
            if(!screen){
                this.props.parent.next()
                return false
            }
            var maxtime=this.props.parent.getScreenTime(screen.id)
            this.finishTimeout=setTimeout(()=>{
                this.finish()
            },maxtime)
        }
    }

    componentWillUnmount()
    {
        console.log("componentWillUnmount EmbedImage")

        if(this.finishTimeout){
            clearTimeout(this.finishTimeout)
        }
    }

    finish()
    {
        console.log("FINISH")

        this.hide()

        this.props.parent.next()
    }

    show()
    {
        console.log("show")
        var elms=document.getElementsByClassName("image-animation")
        for(var elm of elms)
        {
            elm.style.opacity=1
        }
       
    }

    hide()
    {
        var elms=document.getElementsByClassName("image-animation")
        for(var elm of elms)
        {
            elm.style.opacity=0
        }
    }

   
    render()
    {
        var screen=null
        if(this.props.parent.isPreviz())
        {
            screen=this.props.parent.getScreen(this.props.id)
        }else
        {
            screen=this.props.parent.getCurrentScreen()
        }
        console.log(screen)
        if(!screen){
            console.log("no screen")
            return false
        }


        return(
            <div style={{height:"inherit",width:"inherit",display:"flex",justifyContent:"center",alignItems:"center",padding:"1vh 0"}}>
                <img className="image-animation" src={getCachedImage(screen.data.image)} style={{height:"100%",maxWidth:"100vw",objectFit:"contain"}}/>
            </div>
        )
    }
}