import { CandleLightingEvent, HavdalahEvent, HDate, HebrewCalendar, Location, Molad, MoladEvent, OmerEvent, ParshaEvent, RoshChodeshEvent, RoshHashanaEvent, Zmanim } from "@hebcal/core"
import '@hebcal/locales';
import {SolarCalc} from '@hebcal/solar-calc';
// HEBCAL FUNCTIONS

const DAYArray=["Dimanche","Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi"]

export function setLocation(lat,long)
{
    localStorage.setItem("LAT",parseFloat(lat))
    localStorage.setItem("LONG",parseFloat(long))
}

export var HEBCAL=[]

export function generateHebCal(orgConfig={},config={})
{
    var data=[]

    var dateStart=new Date()
    dateStart.setDate(dateStart.getDate()-10)

    var dateEnd=new Date()
    dateEnd.setDate(dateEnd.getDate()+30)
    
    const options = {
        start:dateStart,
        end:dateEnd,
        isHebrewYear: false,
        candlelighting: true,
        locale: 'fr',
        location: new Location(parseFloat(localStorage.getItem("LAT")),parseFloat(localStorage.getItem("LONG"))),
        sedrot: true,
        omer: true,
        noModern:true,
        shabbatMevarchim:true,
        molad:true,
        il:orgConfig.israel==1?true:false,
        
      }

      const events = HebrewCalendar.calendar(options);

      //console.log("HEBCAL events",events)

      var data2={
          hdate:events[0].getDate(),
          date:events[0].getDate().greg(),
          desc:[]
      }
      var time=events[0].getDate().greg().getTime()

      var preventree=false

      for (const ev of events) {
        const hd = ev.getDate();
        const date = hd.greg();

        if(time!=date.getTime())
        {
            time=date.getTime()

            data.push(data2)
            data2={
                hdate:hd,
                date:date,
                desc:[]
            }
        }

       

        data2.desc.push(ev.getDesc())

        if(ev instanceof OmerEvent )
        {
            data2.omer=ev.omer
        }

        if(ev.getDesc().includes("Rosh Hashana") && !ev.getDesc().includes("Erev"))
        {
            data2.yomtov=true
            data2.yomtovType="ROSH_HASHANA"
        }

        if(ev instanceof MoladEvent )
        {
            //CHABBAT MEVARHIM
            //console.log("MEVARHIM",ev)
            data2.mevarhim=true
            data2.mevarhimMonth=ev.getDesc().replace("Molad",'').split(" ").slice(0,-1).join(" ").trim()
            data2.molad=ev.molad
        }

        
        if(ev instanceof RoshChodeshEvent )
        {
            //ROSH HODESH
            //console.log("ROSH HODESH",ev)
            data2.roshHodesh=true
            data2.roshHodeshMonth=ev.getDesc().replace("Rosh Chodesh ",'').trim()
        }

        if(ev instanceof ParshaEvent)
        {
            data2.parasha=ev.parsha.join("-")
        }

        if(ev instanceof HavdalahEvent)
        {
            preventree=false
            switch(config.ZmanimShitat)
            {
                case "DEFAULT":
                    data2.sortieShabbat=new Date(ev.eventTime)
                break;
                case "HABBAD.ORG":
                    var solarCalc = new SolarCalc(new Date(ev.eventTime),parseFloat(localStorage.getItem("LAT")),parseFloat(localStorage.getItem("LONG")));
                    var sun = solarCalc.sun;
                    data2.sortieShabbat=sun.timeAtAngle(8.5, false)
                break;
            }
        }

        if(ev instanceof CandleLightingEvent)
        {
            var dateentree=null

            switch(config.ZmanimShitat)
            {
                case "DEFAULT":
                    dateentree=new Date(ev.eventTime)
                break;
                case "HABBAD.ORG":
                    var solarCalc = new SolarCalc(new Date(ev.eventTime),parseFloat(localStorage.getItem("LAT")),parseFloat(localStorage.getItem("LONG")));
                    var sun = solarCalc.sun;
                    dateentree=sun.timeAtAngle(0.833, false)
                    if(orgConfig.id==2){
                        dateentree= new Date(dateentree.getTime() - 22*60000);
                    }else{
                        dateentree= new Date(dateentree.getTime() - 18*60000);
                    }
                break;
            }

            if (preventree && !data2.yomtov)
            {
                var solarCalc = new SolarCalc(new Date(ev.eventTime),parseFloat(localStorage.getItem("LAT")),parseFloat(localStorage.getItem("LONG")));
                var sun = solarCalc.sun;
                data2.sortieShabbat=sun.timeAtAngle(8.5, false)
            }

            preventree=true
            data2.entreeShabbat=dateentree
        }

        //console.log(date.toLocaleDateString(), ev.render(), hd.toString(),ev.getDesc());
       
      }

      HEBCAL=data

      console.log(HEBCAL)
}

export function getNextParasha(date)
{
    date=new Date(date)
    date.setHours(0,0,0,0);
    for (const item of HEBCAL)
    {
        if(date.getTime()<=item.date.getTime() && item.parasha!=null)
        {
            return item.parasha
        }
    }
}

export function getNextEntreeShabbat(date)
{
    date=new Date(date)
    
    if(date.getDay()==6)//SAMEDI
    {
        date.setDate(date.getDate()-1)
    }

    date.setHours(0,0,0,0);
    for (const item of HEBCAL)
    {
        if(date.getTime()<=item.date.getTime() && item.entreeShabbat!=null)
        {
            return item.entreeShabbat
        }
    }
}

export function getNextSortieShabbat(date)
{
    date=new Date(date)
    date.setHours(0,0,0,0);
    for (const item of HEBCAL)
    {
        if(date.getTime()<=item.date.getTime() && item.sortieShabbat!=null)
        {
            return item.sortieShabbat
        }
    }

    return "error"
}

export function getDateNowFromZman(date)
{
    const zmanim=new Zmanim(new Date(date),parseFloat(localStorage.getItem("LAT")),parseFloat(localStorage.getItem("LONG")))

    if(Date.parse(date)>zmanim.tzeit().getTime())
    {
        var newdate=new Date(date)
        newdate.setDate(newdate.getDate() + 1);
        return newdate
    }

    return date
}

export function getHebrewDate()
{
    var date=getDateNowFromZman(new Date())

    var hdate=new HDate(date)

    return DAYArray[date.getDay()]+" "+hdate.getDate()+" "+hdate.getMonthName()+" "+hdate.getFullYear()
}


export function getHazkara(nichmat,anneeHE)
{

    const nowHE=new HDate(getDateNowFromZman(new Date()))

    var hazkara=new HDate(nichmat.jourHE,nichmat.moisHE,anneeHE)


    //CHECHVAN 30/29
    if(nichmat.jourHE == 30 && nichmat.moisHE == "Cheshvan" && HDate.longCheshvan(anneeHE)==false)
    {
        hazkara=new HDate(29,nichmat.moisHE,anneeHE)
    }

    //KISLEV 30/29
    if(nichmat.jourHE == 30 && nichmat.moisHE == "Kislev" && HDate.shortKislev(anneeHE)==true)
    {
        hazkara=new HDate(29,nichmat.moisHE,anneeHE)
    }


    if(hazkara.getFullYear()==nowHE.getFullYear())
    {
        if(
            (hazkara.getTishreiMonth()==nowHE.getTishreiMonth() && hazkara.getDate()<nowHE.getDate())
            || hazkara.getTishreiMonth()<nowHE.getTishreiMonth()
        )
        {
            return getHazkara(nichmat,anneeHE+1)
        }
    }


    return {
        jourHaz:hazkara.getDate(),
        moisHaz:hazkara.getMonthName(),
        anneeHaz:hazkara.getFullYear()
    }
}


export function  getDiffHDate(item)
{
    const nowHE=new HDate(getDateNowFromZman(new Date()))
    const hazkaraHE=new HDate(item.hazkara.jourHaz,item.hazkara.moisHaz,item.hazkara.anneeHaz)

    return hazkaraHE.deltaDays(nowHE)
}


export function getDateGematria(item)
{
    var dateHE=new HDate(item.jourHE,item.moisHE,item.anneeHE)

    return dateHE.renderGematriya()
}

export function getHebCalByDate(date)
{
    date=('0' + date.getDate()).slice(-2)+"/"+('0' + (date.getMonth() + 1)).slice(-2)+"/"+date.getFullYear()

    for(var e of HEBCAL)
    {
        const date2=('0' + e.date.getDate()).slice(-2)+"/"+('0' + (e.date.getMonth() + 1)).slice(-2)+"/"+e.date.getFullYear()
        if(date2==date)
        {
  
            return e
        }
    }

    return false
}

export function getVariableZman(config,type,date)
{

    switch(config.ZmanimShitat)
    {
        case "DEFAULT":
            return getVariableZmanDefault(type,date)
        break;
        case "HABBAD.ORG":
            return getVariableZmanHabbad(type,date)
        break;
    }
   
}

//CALLJ
export function getVariableZmanDefault(type,date)
{


    const zmanim=new Zmanim(new Date(date),parseFloat(localStorage.getItem("LAT")),parseFloat(localStorage.getItem("LONG")))
    switch(type)
    {
        case "NETS":
            return zmanim.neitzHaChama()
        case "HATSOT":
            return zmanim.chatzot()
        case "PLAG":
            return zmanim.plagHaMincha()
        case "CHKIAH":
            return zmanim.shkiah()
        case "TSET":
            return zmanim.tzeit()
        case "ENTREE_CHABBAT":
            return getHebCalByDate(date).entreeShabbat
        case "SORTIE_CHABBAT":
            return getHebCalByDate(date).sortieShabbat
    }

    return null
}

//HABAD.ORG
export function getVariableZmanHabbad(type,date)
{
    const zmanim=new Zmanim(new Date(),parseFloat(localStorage.getItem("LAT")),parseFloat(localStorage.getItem("LONG")))

    var solarCalc = new SolarCalc(new Date(),parseFloat(localStorage.getItem("LAT")),parseFloat(localStorage.getItem("LONG")));
    var sun = solarCalc.sun;

    const netshamiti=sun.timeAtAngle(1.583, true)
    
    const shkiahamiti=sun.timeAtAngle(1.583, false)
    
    const shaazmanit=(shkiahamiti-netshamiti)/1000/60/12
    
    const hatsotJour=new Date(netshamiti.getTime() + (6*shaazmanit)*60000);
    
    const hatsotNuit=new Date(hatsotJour.getTime() + (12*60)*60000);

    switch(type)
    {
        case "NETS":
            return zmanim.neitzHaChama()
        case "HATSOT":
            return hatsotJour
        case "PLAG":
            return new Date(shkiahamiti.getTime() - (1.25*shaazmanit)*60000)
        case "CHKIAH":
            return sun.timeAtAngle(0.833, false)
        case "TSET":
            return sun.timeAtAngle(6.83, false)
        case "ENTREE_CHABBAT":
            return getHebCalByDate(date).entreeShabbat
        case "SORTIE_CHABBAT":
            return getHebCalByDate(date).sortieShabbat
    }

    return null
}

export function getZmanimDay(config,orgConfig)
{
    var nextFound=false

    var data=null

    console.log(config)

    switch(config.ZmanimShitat)
    {
        case "DEFAULT":
            data=getZmanimDefault()
        break;
        case "HABBAD.ORG":
            data=getZmanimHabad(orgConfig)
        break;
    }
    
    

    data.sort((a,b)=>{
        if(a==b){
            return 0
        }
        return a<b?-1:1
    })

    var dataFinal=[]

    for(var item of data)
    {
        if(!nextFound && item.time>new Date()){
            nextFound=true
            item.next=true
        }
        dataFinal.push(item)
    }

    return data
}


export function isOmer()
{
    var hebcal =getHebCalByDate(new Date())

    if(hebcal.omer)
    {
        return true
    }
    return false
}


export function needMolad()
{
    var hebcal = getHebCalByDate(new Date())

    if(hebcal.mevarhim)
    {
        return true
    }
    return false
}


//HABBD.ORG
export function getZmanimHabad(orgConfig)
{
    var data=[]

    const zmanim=new Zmanim(new Date(),parseFloat(localStorage.getItem("LAT")),parseFloat(localStorage.getItem("LONG")))

    var solarCalc = new SolarCalc(new Date(),parseFloat(localStorage.getItem("LAT")),parseFloat(localStorage.getItem("LONG")));
    var sun = solarCalc.sun;

    const netshamiti=sun.timeAtAngle(1.583, true)
    
    const shkiahamiti=sun.timeAtAngle(1.583, false)
    
    const shaazmanit=(shkiahamiti-netshamiti)/1000/60/12
    
    const hatsotJour=new Date(netshamiti.getTime() + (6*shaazmanit)*60000);
    
    const hatsotNuit=new Date(hatsotJour.getTime() + (12*60)*60000);

    var alotHachahar=sun.timeAtAngle(26.0, true)

    if(isNaN(alotHachahar)){
        var yesterday=new Date()
        yesterday.setDate(yesterday.getDate()-1);
        alotHachahar=getZmanimHabadHatsotNuit(yesterday)
    }

    data.push({
        nom:"Alot Hashahar 120m",
        time:alotHachahar
    })

    data.push({
        nom:"Alot Hashahar 72m",
        time:sun.timeAtAngle(16.9, true)
    })

    data.push({
        nom:"Tefiline et Shema",
        time:sun.timeAtAngle(10.2, true)
    })

    data.push({
        nom:"Nets Hahama",
        time:zmanim.neitzHaChama()
    })

    data.push({
        nom:"Max Chema",
        time:new Date(netshamiti.getTime() + (3*shaazmanit)*60000)
    })

    data.push({
        nom:"Max Tefila",
        time:new Date(netshamiti.getTime() + (4*shaazmanit)*60000)
    })

    data.push({
        nom:"Hatsot Yom",
        time:hatsotJour
    })
    
    data.push({
        nom:"Minha Gdola",
        time:new Date(netshamiti.getTime() + (6.5*shaazmanit)*60000)
    })

    data.push({
        nom:"Minha Ktana",
        time:new Date(shkiahamiti.getTime() - (2.5*shaazmanit)*60000)
    })

    data.push({
        nom:"Plag Minha",
        time:new Date(shkiahamiti.getTime() - (1.25*shaazmanit)*60000)
    })

    data.push({
        nom:"Shkia",
        time:sun.timeAtAngle(0.833, false)
    })
    
    if(orgConfig.id==2)
    {
        data.push({
            nom:"Tset Hakokhavim",
            time:sun.timeAtAngle(6.83, false)
        })

    }else
    {
        data.push({
            nom:"Tset Hakokhavim",
            time:sun.timeAtAngle(6, false)
        })
    
        data.push({
            nom:"Tset Deoraita",
            time:sun.timeAtAngle(6.83, false)
        })
    }
    

    data.push({
        nom:"Hatsot nuit",
        time:hatsotNuit
    })


    return data

}


//HABBD.ORG
export function getZmanimHabadHatsotNuit(date)
{

    var solarCalc = new SolarCalc(date,parseFloat(localStorage.getItem("LAT")),parseFloat(localStorage.getItem("LONG")));
    var sun = solarCalc.sun;

    const netshamiti=sun.timeAtAngle(1.583, true)
    
    const shkiahamiti=sun.timeAtAngle(1.583, false)
    
    const shaazmanit=(shkiahamiti-netshamiti)/1000/60/12
    
    const hatsotJour=new Date(netshamiti.getTime() + (6*shaazmanit)*60000);
    
    const hatsotNuit=new Date(hatsotJour.getTime() + (12*60)*60000);

    return hatsotNuit

}

//CALJ
export function getZmanimDefault(){ 
    var data=[]

    const zmanim=new Zmanim(new Date(),parseFloat(localStorage.getItem("LAT")),parseFloat(localStorage.getItem("LONG")))

    data.push({
        nom:"Alot Hashahar",
        time:zmanim.alotHaShachar()
    })

    data.push({
        nom:"Tefiline et Shema",
        time:zmanim.misheyakir()
    })

    data.push({
        nom:"Nets Hahama",
        time:zmanim.neitzHaChama()
    })

    data.push({
        nom:"Max Chema",
        time:zmanim.sofZmanShma()
    })

    data.push({
        nom:"Max Tefila",
        time:zmanim.sofZmanTfilla()
    })

    data.push({
        nom:"Hatsot Yom",
        time:zmanim.chatzot()
    })
    
    data.push({
        nom:"Minha Gdola",
        time:zmanim.minchaGedola()
    })

    data.push({
        nom:"Minha Ktana",
        time:zmanim.minchaKetana()
    })

    data.push({
        nom:"Plag Minha",
        time:zmanim.plagHaMincha()
    })

    data.push({
        nom:"Shkia",
        time:zmanim.shkiah()
    })
    
    data.push({
        nom:"Tset Hakokhavim",
        time:zmanim.tzeit()
    })

    data.push({
        nom:"Hatsot nuit",
        time:zmanim.chatzotNight()
    })


    return data
}



export function getNextRoshHosdesh(date)
{
    var data=[]
    var hd = new HDate(date);
    const month=hd.getMonthName()
    var nextMonth=null
    
    var i=0
    while(nextMonth==null)
    {
        hd=hd.add(i)
        //console.log(hd)
        if(month!=hd.getMonthName()){
            nextMonth=hd.getMonthName()
        }
        i++
    }

    for(var h of HEBCAL)
    {
        if(h.roshHodesh && h.roshHodeshMonth==nextMonth){
            data.push(h)
        }
    }

    return data
}


export function getMolad(month)
{
    
    for(var h of HEBCAL)
    {
        if(h.mevarhim && h.mevarhimMonth==month){
            return h.molad
        }
    }

    return null
}


export function isChabbat()
{
    var date=getDateNowFromZman(new Date())

    if(date.getDay()==6){
        return true
    }

    return false
}


export function isYomTov()
{
    var hebcal = getHebCalByDate(new Date())

    if(hebcal.yomtov)
    {
        return true
    }
    return false
}
