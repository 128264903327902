import React from "react";
import { BASE_MEDIA_URL, fontSize, getCachedImage, REFRESH_PLAQUE_INTERVAL_TIME } from "../config";


export default class CustomImage extends React.Component{

    componentDidUpdate(prevProps)
    {
        if(prevProps.id!==this.props.id){
            this.init()
        }
    }
    
    componentDidMount()
    {
        this.init()
    }

    init()
    {
        setTimeout(()=>this.show(),500)

        if(!this.props.parent.isPreviz())
        {
            const screen=this.props.parent.getCurrentScreen()
            if(!screen){
                return false
            }
            var maxtime=this.props.parent.getScreenTime(screen.id)
            this.finishTimeout=setTimeout(()=>{
                this.finish()
            },maxtime)
        }
    }

    componentWillUnmount()
    {
        if(this.finishTimeout){
            clearTimeout(this.finishTimeout)
        }
    }

    finish()
    {
        console.log("FINISH")

        this.hide()

        setTimeout(()=>this.props.parent.next(false),500)
        
    }

    show()
    {
        var elms=document.getElementsByClassName("image-animation")
        for(var elm of elms)
        {
            elm.style.opacity=1
        }
       
    }

    hide()
    {
        var elms=document.getElementsByClassName("image-animation")
        for(var elm of elms)
        {
            elm.style.opacity=0
        }
    }

   
    render()
    {
        const screen=this.props.parent.getScreen(this.props.id)
        console.log(screen)
        if(!screen){
            return null
        }

        return(
            <div style={{
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                backgroundColor:"#000"
            }}>
                <img className="image-animation" src={getCachedImage(screen.data.image)} style={{height:"100vh",maxWidth:"100vw",objectFit:"contain"}}/>
            </div>
            
        )
    }
}